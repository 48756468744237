import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const authStyles = makeStyles()((theme) => ({
  loginForm: {
    [theme.breakpoints.down(768)]: {
      paddingTop: "24px",
      paddingBottom: "48px",
    },
  },
  forgotLink: {
    color: colorTable.charcoalBlack,
    padding: "0",
    justifyContent: "left",
    margin: "0",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "none",
    [theme.breakpoints.down(768)]: {
      lineHeight: "20px",
    },

    "&:hover": {
      color: colorTable.nobleOchre,
      backgroundColor: colorTable.transparent,
    },
  },
  forgotLinkMobile: {
    fontSize: "32px",
    color: colorTable.blue_20,
    padding: "0",
    justifyContent: "left",
  },
  white: {
    color: colorTable.white,
  },
  field: {
    width: "100%",
    position: "relative",
  },
  fieldMobile: {
    width: "100%",
    position: "relative",
    marginBottom: "32px",
  },
  form: {
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  inputMobile: {
    "& .MuiInputBase-input": {
      textOverflow: "ellipsis",
      paddingLeft: "16px",
      paddingBottom: "0",
      fontSize: "36px",
    },

    "& .MuiFilledInput-root": {
      background: colorTable.white,
      border: `1px solid ${borderColors.lightGrey_50}`,
      borderRadius: "8px",

      "&:hover": {
        border: `1px solid ${borderColors.nobleOchre}`,
        background: colorTable.white,
      },

      "&.Mui-focused": {
        border: `1px solid ${borderColors.nobleOchre}`,
        background: colorTable.white,
      },

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.white_10,
      },
    },

    "& label": {
      fontSize: "30px",
    },
    "& div": {
      height: "12vw",
      fontSize: "40px",
    },
    "& div:hover": {
      background: colorTable.white,
    },
    "& input": {
      height: "100%",
    },
    "& div > button > span > svg": {
      width: "40px",
      height: "40px",
    },
    "& .MuiSvgIcon-root": {
      width: "2em",
      height: "2em",
      top: "20%",
    },
    "& p": {
      fontSize: "28px",
    },
  },
  inputPhoneNumberMobile: {
    "& .MuiInputBase-input": {
      paddingTop: "8px !important",
    },
  },
  button: {
    height: "12vw",
    fontSize: "30px",
    borderRadius: "4px",
    background: colorTable.darkBlue,
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
  },
  buttonDesktop: {
    width: "100%",
  },
  inputLabel: {
    color: colorTable.darkGrey_10,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    "&.labelImportant": {
      fontSize: "14px",
    },
  },
  inputLabelMobile: {
    color: colorTable.darkGrey_10,
    textAlign: "left",
    marginBottom: "16px",
    fontSize: "28px",
  },
  inputLabelOtp: {
    color: colorTable.misteryBlue,
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "24px",
    lineHeight: "24px",
    "&.MuiInputLabel-root": {
      whiteSpace: "pre-wrap",
    },
  },
  inputLabelOtpMobile: {
    color: colorTable.misteryBlue,
    fontSize: "32px",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    marginBottom: "24px",
    "&.MuiInputLabel-root": {
      whiteSpace: "pre-wrap",
    },
  },
  icon: {
    position: "absolute",
    top: "43%",
    right: "3%",
  },
  textField: {
    marginBottom: "8px",
  },
  otpForm: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "480px",
    marginTop: "50px",
    [theme.breakpoints.down(768)]: {
      width: "100%",
      marginTop: "24px",
    },
  },
  otpFormMobile: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "35px",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column-reverse",
      gap: "8px",
    },
  },
  buttonsWrapperMobile: {
    display: "flex",

    "& div": {
      marginBottom: "32px",
    },
  },
  header: {
    fontSize: "24px",
    fontWeight: 400,
    color: colorTable.charcoalBlack,
    marginTop: "40px",
    marginBottom: "32px",
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginTop: "16px",
      marginBottom: "17px",
      letterSpacing: "-0.7px",
    },
    "& h2": {
      fontSize: "24px",
      fontWeight: 400,
      [theme.breakpoints.down(768)]: {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
  },
  progressTitle: {
    margin: "40px 0 32px 0 ",
  },
  bottomRegistrationWrapper: {
    marginBottom: "10px",
    marginTop: "16px",
    lineHeight: "20px",

    "& a": {
      width: "auto",
      display: "inline",
    },
  },
  newClientText: {
    fontSize: "14px",
    marginRight: "4px",
    color: colorTable.moonGrey,
  },
  newClientTextMobile: {
    fontSize: "32px",
    color: colorTable.moonGrey,
    marginRight: "2px",
  },
  authError: {
    width: "100%",
    display: "flex",
    height: "48px",
    background: colorTable.white_35,
    borderRadius: "4px",
    alignItems: "center",
    paddingLeft: "23px",
    marginBottom: "24px",
    marginTop: "-8px",

    "& i": {
      marginRight: "16px",
    },

    "& p": {
      color: colorTable.blackBlue,
      fontSize: "16px",
    },
  },
  otpSendCodeWrapper: {
    marginTop: "48px",
    marginBottom: "115px",
    [theme.breakpoints.down(768)]: {
      marginTop: "16px",
      marginBottom: "40px",
    },
  },
  loginButtonsWrapper: {
    display: "flex",
  },
  toggleButton: {
    width: "50%",
    minHeight: "32px",
    height: "min-content",
    fontSize: "14px",
    padding: "6px 12px 6px 12px",
    background: colorTable.white_10,
    color: colorTable.lightGrey_90,
    "&:hover": {
      background: colorTable.white_10,
    },
    [theme.breakpoints.down(768)]: {
      lineHeight: "20px",
      padding: "0px",
    },
  },
  toggleButtonMobile: {
    width: "480px",
    height: "64px",
    fontSize: "28px",
    padding: "6px 12px 6px 12px",
    background: colorTable.white_10,
    color: colorTable.lightGrey_90,
    "&:hover": {
      background: colorTable.white_10,
    },
  },
  toggleButtonActive: {
    width: "50%",
    minHeight: "32px",
    height: "min-content",
    padding: "6px 12px 6px 12px",
    fontSize: "14px",
    background: colorTable.lightGrey_90,
    color: colorTable.white,
    "&:hover": {
      background: colorTable.lightGrey_90,
    },
    [theme.breakpoints.down(768)]: {
      lineHeight: "20px",
      padding: "0px",
    },
  },
  toggleButtonActiveMobile: {
    width: "480px",
    height: "64px",
    fontSize: "28px",
    padding: "6px 12px 6px 12px",
    background: colorTable.lightGrey_90,
    color: colorTable.white,
    "&:hover": {
      background: colorTable.lightGrey_90,
    },
  },
  errorBox: {
    display: "flex",
    flexDirection: "column",
  },
  errorTextWrapper: {
    display: "flex",
  },
  errorTextWrapperMobile: {
    display: "flex",
    marginBottom: "16px",
  },
  errorTextFieldText: {
    fontSize: "12px",
    color: colorTable.red,
    textAlign: "left",
  },
  errorTextFieldImage: {
    marginRight: "8px",
    marginTop: "5px",
  },
  errorTextFieldTextMobile: {
    fontSize: "24px",
    color: colorTable.red,
    textAlign: "left",
  },
  errorTextFieldImageMobile: {
    marginRight: "16px",
    marginTop: "10px",
  },
  numberTextField: {
    width: "100%",
    "& label": {
      display: "none",
    },
  },
  sendOtpCode: {
    color: colorTable.blue_20,
    padding: "0",
    justifyContent: "left",
    margin: "0",
    fontSize: "14px",
    cursor: "pointer",
    width: "27%",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },

    "&:hover": {
      color: colorTable.blue_60,
      backgroundColor: colorTable.transparent,
    },
  },
  submitBtnBox: {
    marginBottom: "48px",
    [theme.breakpoints.down(768)]: {
      marginBottom: 0,
    },
  },
  forgotLinkAuth: {
    color: colorTable.charcoalBlack,
    "&:hover": {
      color: colorTable.nobleOchre,
    },
  },
  recaptureWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  buttonForgotWrapper: {
    width: "170px",
    marginTop: "8px",
    marginBottom: "32px",
    textAlign: "left",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  formBtn: {
    minWidth: "135px",
    [theme.breakpoints.down(768)]: {
      minWidth: "initial",
      width: "100%",
    },
  },
  backBtn: {
    width: "135px",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  loaderWrapper: {
    position: "absolute",
    left: "15px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  tabsWrapper: {
    marginTop: "25px",
    marginBottom: "24px",
  },
}));
