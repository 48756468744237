import { createDomain, createEffect, forward, merge, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";
import { pending } from "patronum";

import { LocalStorageKeysEnum, processStatus } from "~/constants/enums";
import { edmTextPattern, notDigitPattern } from "~/constants/regexp";
import { CountryCode, ProcessStateRequest, RegistrationDataModel } from "~/entities/keycloak";
import { authApi } from "~/entities/keycloak/api";
import { OTP_DONT_MATCH, processResultEnum, taskDefinitionKeyEnum } from "~/entities/productBuy/types";
import { t } from "~/i18n";
import { autofillOtpCode } from "~/lib/autofillOtpCode";
import { showOtpSecretError } from "~/lib/errorHandlers";
import token from "~/lib/token";
import { maxLengthPassword, minLengthPassword, rules } from "~/lib/validation/rules";
import { firebaseModel } from "~/modules/firebase";
import { customerPrivacyNoticeType, Steps } from "~/modules/keycloak/registration-model/types";
import { notificationModel } from "~/modules/notification-model";
import { TGetBusinessKeyError } from "~/shared/types";

const registrationDomain = createDomain("registration");

const registrationFx = createReEffect<RegistrationDataModel, any, TGetBusinessKeyError>({
  handler: authApi.registration,
});

const customerPrivacyNoticeFx = createEffect({
  handler: authApi.customerPrivacyNotice,
});

const registrationProcessFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});
const otpProcessFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});
const questionProcessFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});
const completeTaskRegistrationFx = createReEffect<any, any, any>({
  handler: authApi.completeTask,
});
const getQuestionListFx = createReEffect<any, any, any>({
  handler: authApi.getQuestionList,
});
const completeTaskQuestionFx = createReEffect<any, any, any>({
  handler: authApi.completeTask,
});

const setRecaptchaValue = registrationDomain.createEvent<string>();
const setTokens = registrationDomain.createEvent<{ resultData?: { access_token?: string; refresh_token: string } }>();
setTokens.watch((processInfo: { resultData?: { access_token?: string; refresh_token: string } }) => {
  token.set({
    access_token: processInfo?.resultData?.access_token || "",
    refresh_token: processInfo?.resultData?.refresh_token || "",
  });
});

const $recaptchaValue = registrationDomain.createStore<string>("service is unavailable");
$recaptchaValue.on(setRecaptchaValue, (_, data) => data);

const getCountryCodeFx = createReEffect<any, CountryCode[], any>({ handler: authApi.getCountryCode });

const gate = createGate({ domain: registrationDomain });

const $currentCountyCode = registrationDomain.createStore<string | undefined>("");

const otpForm = createForm({
  fields: {
    otp: {
      init: "",
      rules: [rules.less(6), rules.required()],
    },
  },
  domain: registrationDomain,
  validateOn: ["submit"],
});

const setOtp = registrationDomain.createEvent();
const clearOtp = registrationDomain.createEvent();
const submitOtpRegistration = registrationDomain.createEvent();
const submitOtpQuestion = registrationDomain.createEvent();
const setStep = registrationDomain.createEvent<Steps>();
const setQuestionId = registrationDomain.createEvent();
const setAnswer = registrationDomain.createEvent<string>();
const acceptedCustomerPrivacy = registrationDomain.createEvent<boolean>();
const setErrorAlreadyExist = registrationDomain.createEvent<boolean>();
const getNewOtpCode = registrationDomain.createEvent<void>();
const isOtpError = registrationDomain.createEvent<boolean>();
const setCountryCode = registrationDomain.createEvent<string>();
const getCustomerPrivacyNotice = registrationDomain.createEvent();
const setBusinessKey = registrationDomain.createEvent<string>();
const setHasLocalStorageBusinessKey = registrationDomain.createEvent<boolean>();

const $questionsList = registrationDomain.createStore<any>(null);
$questionsList.reset(gate.close);
$questionsList.on(getQuestionListFx.doneData, (_, data) => data.content);

const $questionId = registrationDomain.createStore<any>(0);
$questionId.reset(gate.close);
$questionId.on(setQuestionId, (_, data) => data);

const $answer = registrationDomain.createStore<string>("");
$answer.reset(gate.close);
$answer.on(setAnswer, (_, data: string) => data);

const $processInfo = registrationDomain.createStore<any>(null);
$processInfo.reset(gate.close);
$processInfo.on([registrationProcessFx.doneData, otpProcessFx.doneData, questionProcessFx.doneData], (_, data) => data);

const $businessKey = registrationDomain.createStore<string>("");
$businessKey.reset(gate.close);
$businessKey.on(registrationFx.doneData, (_, data) => data.data.businessKey);
$businessKey.on(setBusinessKey, (_, data) => data);

const $hasLocalStorageBusinessKey = registrationDomain.createStore<boolean>(false);
$hasLocalStorageBusinessKey.reset(gate.close);
$hasLocalStorageBusinessKey.on(setHasLocalStorageBusinessKey, (_, data) => data);

const $agreeCustomerPrivacy = registrationDomain.createStore<boolean>(false);
$agreeCustomerPrivacy.on(acceptedCustomerPrivacy, (_, accept) => accept);

const $hasErrorUserAlreadyExist = registrationDomain.createStore<boolean>(false);
$hasErrorUserAlreadyExist.on(setErrorAlreadyExist, (_, error) => error);

const $countryCodes = registrationDomain.createStore<CountryCode[]>([]);
$countryCodes.on(getCountryCodeFx.doneData, (_, data) => data);

const $codeIso = registrationDomain.createStore<string>("");
$codeIso.on(setCountryCode, (_, code) => code);

forward({ from: gate.open, to: getCountryCodeFx });

const registrationForm = createForm({
  fields: {
    username: {
      init: "",
      rules: [rules.phoneNumberIsField($countryCodes, $codeIso), rules.phoneNumberIsValid($currentCountyCode)],
    },
    email: {
      init: "",
      rules: [rules.email(), rules.required()],
    },
    password: {
      init: "",
      rules: [
        rules.required(),
        rules.password(),
        {
          name: "password short",
          validator: (newPass: string) => newPass.trim().length >= minLengthPassword,
          errorText: "REGISTRATION.error.1",
        },
        {
          name: "password long",
          validator: (password: string) => password.trim().length < maxLengthPassword,
          errorText: "VALIDATION.long-password",
        },
      ],
      validateOn: ["change"],
    },
    passwordConfirm: {
      init: "",
      rules: [
        {
          name: "password check symbols",
          validator: (value, { password }) => value === password,
          errorText: "VALIDATION.mismatch-passwords",
        },
      ],
      validateOn: ["change"],
    },
    firstName: {
      init: "",
      rules: [
        {
          name: "firstName is empty",
          validator: (firstName: string) => firstName.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
        rules.lessAndMoreWithoutT(50, 2),
        {
          name: "true name",
          validator: (val: string, form) => {
            if (!!form.hasNoMiddleName) return true;
            return val ? edmTextPattern.test(val) : true;
          },
          errorText: "VALIDATION.edm-name-pattern",
        },
      ],
    },
  },
  domain: registrationDomain,
  validateOn: ["submit"],
});

//event для проверки работоспобности sample
const submit = registrationDomain.createEvent<void>();
const goToFirstStep = registrationDomain.createEvent<void>();
const goToSecondStep = registrationDomain.createEvent<void>();
const goToThirdStep = registrationDomain.createEvent<void>();
const goToOtp = registrationDomain.createEvent<void>();
const goToOtpQuestion = registrationDomain.createEvent<void>();
const registration = registrationDomain.createEvent<void>();

const $otpError = registrationDomain.createStore<boolean>(false);
$otpError.reset(goToSecondStep);
$otpError.on(isOtpError, (_, status) => status);
$otpError.reset(otpForm.fields.otp.onChange);

const $currentStep = registrationDomain.createStore<Steps>(Steps.EnterUserCredentials);
$currentStep.on(setStep, (_, step) => step);
$currentStep.reset(gate.close);

const $customerPrivacyNoticeDoc = registrationDomain.createStore<customerPrivacyNoticeType>({
  docName: "",
  content: "",
});
const resetPrivacyDoc = registrationDomain.createEvent();
$customerPrivacyNoticeDoc.reset(resetPrivacyDoc);

forward({ from: goToFirstStep, to: setStep.prepend(() => Steps.EnterUserCredentials) });
forward({ from: goToThirdStep, to: [getQuestionListFx, setStep.prepend(() => Steps.EnterSecurityQuestion)] });
forward({ from: getCustomerPrivacyNotice, to: customerPrivacyNoticeFx });

sample({
  source: [$countryCodes, $codeIso] as const,
  clock: getCountryCodeFx.doneData,
  fn: ([country, codeIso]) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    return codeIso || (countryPreview?.codeIso ?? "");
  },
  target: $codeIso,
});

sample({
  source: [$countryCodes, $codeIso] as const,
  clock: getCountryCodeFx.doneData,
  fn: ([country, codeIso]) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    const chosenCountry = country.find((item) => item.codeIso === codeIso);

    return chosenCountry?.phoneCode || (countryPreview?.phoneCode ?? "");
  },
  target: registrationForm.fields.username.onChange,
});

sample({
  source: [$countryCodes, $codeIso] as const,
  fn: ([countries, codeIso]) => countries.find((el) => el.codeIso === codeIso)?.charCode2,
  target: $currentCountyCode,
});

sample({
  clock: goToSecondStep,
  filter: () => {
    registrationForm.fields.username.validate();
    registrationForm.fields.email.validate();
    registrationForm.fields.password.validate();
    registrationForm.fields.passwordConfirm.validate();
    otpForm.reset();
    const firstFormIsValid =
      registrationForm.fields.username.$isValid.getState() &&
      registrationForm.fields.email.$isValid.getState() &&
      registrationForm.fields.password.$isValid.getState() &&
      registrationForm.fields.passwordConfirm.$isValid.getState();
    return firstFormIsValid;
  },
  target: setStep.prepend(() => Steps.EnterAdditionalCredentials),
});

sample({
  clock: goToOtp,
  filter: () => {
    registrationForm.fields.firstName.validate();
    return registrationForm.$isValid.getState();
  },
  target: registration,
});

//отправляем форму регистрации. Ждем businessKey

sample({
  source: [registrationForm.$values, $countryCodes, $codeIso, $recaptchaValue, firebaseModel.$otpSecret] as const,
  clock: registration,
  fn: ([values, countryCodes, codeIso, recaptcha, otpSecret]) => {
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    const phoneNumber = "+" + values.username.replace(notDigitPattern, "");

    return {
      username: {
        login: phoneNumber,
        region: countryCode?.charCode2 ?? "",
      },
      email: values.email,
      firstName: values.firstName,
      password: values.password,
      cpnAgreement: true,
      recaptcha,
      otpSecret,
    };
  },
  target: registrationFx,
});

sample({
  source: $processInfo,
  clock: getNewOtpCode,
  fn: (processInfo) => {
    return {
      taskId: processInfo?.activeTasks[0]?.id ?? "",
      variables: {
        enteredCode: null,
      },
    };
  },
  target: completeTaskRegistrationFx,
});

//получаем состояние процесса по businessKey
sample({
  source: registrationFx.doneData,
  fn: (data): ProcessStateRequest => ({ processBusinessKey: data.data.businessKey }),
  target: registrationProcessFx,
});

//если нет таски, то делаем повторный запрос, пока в массиве не будет activeTask
sample({
  source: registrationProcessFx.doneData,
  filter: (data) =>
    !data.activeTasks.length &&
    data.processResult !== processResultEnum.TIME_EXPIRED &&
    data.processResult !== processResultEnum.SECURITY_QUESTION_TIME_EXPIRED &&
    data.processResult !== processResultEnum.INVALID_OTP_FIRST_BLOCK &&
    data.processResult !== processResultEnum.INVALID_OTP_SECOND_BLOCK &&
    data.processResult !== processResultEnum.USERNAME_PERMANENTLY_BLOCKED &&
    data.processResult !== processResultEnum.CANNOT_SEND_SMS &&
    data.processResult !== processResultEnum.CANNOT_CHECK_OTP_COUNTER &&
    data.processResult !== processResultEnum.UNSUPPORTED_ERROR &&
    data.processResult !== processResultEnum.SERVER_ERROR &&
    data.state !== processStatus.DONE,
  target: registrationProcessFx,
});

//если есть activeTask, то переходим на форму ввода ОТП
sample({
  source: registrationProcessFx.doneData,
  clock: registrationProcessFx.doneData,
  filter: (data) => data.activeTasks.length,
  target: setStep.prepend(() => Steps.EnterOtpCode),
});

registrationProcessFx.doneData.watch((data) => {
  autofillOtpCode(data?.resultData?.debugData, () => otpForm.setForm({ otp: data.resultData.debugData }));
});

//берем из состояния процесса taskId и закрываем процесс, посредством отправки отп и taskId по процессу регистрации
sample({
  source: [$processInfo, otpForm.$values],
  clock: submitOtpRegistration,
  fn: ([processInfo, otp]) => {
    return {
      taskId: processInfo?.activeTasks[0]?.id ?? "",
      variables: {
        enteredCode: otp.otp,
      },
    };
  },
  target: completeTaskRegistrationFx,
});

sample({
  source: $businessKey,
  clock: completeTaskRegistrationFx.doneData,
  fn: (businessKey) => ({ processBusinessKey: businessKey }),
  target: otpProcessFx,
});

sample({
  source: [$businessKey, $hasLocalStorageBusinessKey] as const,
  clock: $hasLocalStorageBusinessKey,
  filter: ([businessKey, hasLocalStorageBusinessKey]) => !!businessKey && hasLocalStorageBusinessKey,
  fn: ([businessKey]) => ({ processBusinessKey: businessKey }),
  target: otpProcessFx,
});

sample({
  source: otpProcessFx.doneData,
  clock: otpProcessFx.doneData,
  filter: (data) =>
    !data.activeTasks.length &&
    data.processResult !== processResultEnum.INVALID_OTP_FIRST_BLOCK &&
    data.processResult !== processResultEnum.INVALID_OTP_SECOND_BLOCK &&
    data.processResult !== processResultEnum.USERNAME_PERMANENTLY_BLOCKED &&
    data.processResult !== processResultEnum.USER_ALREADY_EXISTS &&
    data.processResult !== processResultEnum.SERVER_ERROR,
  target: otpProcessFx.prepend(() => ({ processBusinessKey: $businessKey.getState() })),
});

sample({
  source: otpProcessFx.doneData,
  clock: otpProcessFx.doneData,
  filter: (data) => data.processResult === processResultEnum.USER_ALREADY_EXISTS,
  target: setErrorAlreadyExist.prepend(() => true),
});

sample({
  source: $hasErrorUserAlreadyExist,
  clock: setErrorAlreadyExist,
  filter: (data) => data,
  target: setStep.prepend(() => Steps.UserAlreadyExist),
});

sample({
  source: otpProcessFx.doneData,
  clock: otpProcessFx.doneData,
  filter: (data) => {
    otpForm.reset();
    return data.resultData.error_message === OTP_DONT_MATCH;
  },
  target: isOtpError.prepend(() => true),
});

sample({
  clock: otpProcessFx.doneData,
  filter: (data) => {
    if (!data.activeTasks.length || data.processResult) {
      return false;
    }
    return data.activeTasks[0]?.taskDefinitionKey !== taskDefinitionKeyEnum.OTP_CONFIRMATION;
  },
  target: goToThirdStep,
});

sample({
  clock: otpProcessFx.doneData,
  filter: (data) => {
    return data.activeTasks?.[0]?.taskDefinitionKey === taskDefinitionKeyEnum.ENTER_QA_TASK;
  },
  target: setStep.prepend(() => Steps.EnterSecurityQuestion),
});

otpProcessFx.doneData.watch((data) => {
  if (data.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK) {
    setStep(Steps.INVALID_OTP_FIRST_BLOCK);
  }
  if (data.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK) {
    setStep(Steps.INVALID_OTP_SECOND_BLOCK);
  }
  if (data.processResult === processResultEnum.USERNAME_PERMANENTLY_BLOCKED) {
    setStep(Steps.USERNAME_PERMANENTLY_BLOCKED);
  }
  if (data.processResult === processResultEnum.SERVER_ERROR) {
    notificationModel.error(t("ERRORS.service_temp_unavailable"));
  }
});

registrationProcessFx.doneData.watch((data) => {
  if (data.processResult === processResultEnum.OTP_SECRET_DOES_NOT_MATCH) {
    return firebaseModel.otpSecretDoesNotMatch();
  }
  if (data.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK) {
    setStep(Steps.INVALID_OTP_FIRST_BLOCK);
  }
  if (data.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK) {
    setStep(Steps.INVALID_OTP_SECOND_BLOCK);
  }
  if (data.processResult === processResultEnum.USERNAME_PERMANENTLY_BLOCKED) {
    setStep(Steps.USERNAME_PERMANENTLY_BLOCKED);
  }
  if (data.processResult === processResultEnum.TIME_EXPIRED) {
    setStep(Steps.TIME_EXPIRED);
  }
  if (data.processResult === processResultEnum.CANNOT_SEND_SMS) {
    notificationModel.error(t("REGISTRATION.error.sms_error"));
  }
  if (data.processResult === processResultEnum.CANNOT_CHECK_OTP_COUNTER) {
    notificationModel.error(t("REGISTRATION.error.sms_error"));
  }
  if (data.processResult === processResultEnum.UNSUPPORTED_ERROR) {
    notificationModel.error(t("ERRORS.smth_went_wrong"));
  }
  if (data.processResult === processResultEnum.SERVER_ERROR) {
    notificationModel.error(t("ERRORS.service_temp_unavailable"));
  }
});

sample({
  source: $businessKey,
  clock: completeTaskQuestionFx.doneData,
  fn: (businessKey) => ({ processBusinessKey: businessKey }),
  target: questionProcessFx,
});

//если истекло время ожидания, выбрасываем ошибку об этом
const mergedProcess = merge([registrationProcessFx.doneData, questionProcessFx.doneData]);
sample({
  source: mergedProcess,
  filter: (data: any) =>
    !data.activeTasks.length &&
    (data.processResult === processResultEnum.TIME_EXPIRED ||
      data.processResult === processResultEnum.SECURITY_QUESTION_TIME_EXPIRED),
  target: setStep.prepend(() => Steps.TIME_EXPIRED),
});

//если нет таски, то делаем повторный запрос, пока в массиве не будет activeTask

//если есть processResult SUCCESSFUL_REGISTRATION то переходим на страницу успешной регистрации
sample({
  source: questionProcessFx.doneData,
  filter: (data) => data.processResult === "SUCCESSFUL_REGISTRATION",
  target: [$processInfo, setTokens, setStep.prepend(() => Steps.SUCCESSFUL_REGISTRATION)],
});

sample({
  source: [$processInfo, $questionId, $answer],
  clock: goToOtpQuestion,
  fn: ([processInfo, questionId, answer]) => {
    return {
      taskId: processInfo?.activeTasks[0]?.id ?? "",
      variables: {
        question_id: questionId,
        answer: answer.trim(),
      },
    };
  },
  target: completeTaskQuestionFx,
});

sample({
  source: customerPrivacyNoticeFx.doneData,
  fn: (data: any) => ({
    content: data.base64Content,
    docName: data.bcsfsName,
  }),
  target: $customerPrivacyNoticeDoc,
});

sample({
  source: $businessKey,
  clock: [completeTaskRegistrationFx.fail, completeTaskQuestionFx.fail],
  fn: (businessKey, failData) => {
    if (failData.error.error.response.status === 404) {
      return { processBusinessKey: businessKey };
    }
  },
  target: registrationProcessFx,
});

registrationFx.fail.watch((data) => {
  showOtpSecretError(data, () => notificationModel.errorDefaultServer(data as any));
});

registrationProcessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

questionProcessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

completeTaskRegistrationFx.fail.watch((failData: any) => {
  if (failData.error?.error?.response?.status !== 404) {
    notificationModel.errorDefaultServer(failData);
  }
});

getQuestionListFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

completeTaskQuestionFx.fail.watch((failData: any) => {
  if (failData.error?.error?.response?.status !== 404) {
    notificationModel.errorDefaultServer(failData);
  }
});

$processInfo.watch((data: any) => {
  const businessKey = $businessKey.getState();

  if (data?.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeyEnum.ENTER_QA_TASK && businessKey) {
    localStorage.setItem(LocalStorageKeysEnum.registrationBusinessKey, businessKey);
  }
});

gate.open.watch(() => {
  const registrationBusinessKey = localStorage.getItem(LocalStorageKeysEnum.registrationBusinessKey);

  if (registrationBusinessKey) {
    setBusinessKey(registrationBusinessKey);
    setHasLocalStorageBusinessKey(true);
  }
});

export const registrationModel = {
  registrationForm,
  registrationGate: gate,
  otpForm,
  clearOtp,
  setOtp,
  submit,
  goToFirstStep,
  goToSecondStep,
  goToThirdStep,
  acceptedCustomerPrivacy,
  $agreeCustomerPrivacy,
  goToOtp,
  setErrorAlreadyExist,
  $hasErrorUserAlreadyExist,
  $currentStep,
  setStep,
  submitOtpRegistration,
  submitOtpQuestion,
  $questionsList,
  $questionId,
  setQuestionId,
  setAnswer,
  $answer,
  getNewOtpCode,
  $otpError,
  goToOtpQuestion,
  $countryCodes,
  setCountryCode,
  $codeIso,
  $registrationPenging: registrationFx.pending,
  $registrationProcessFxPending: registrationProcessFx.pending,
  $otpProcessFxPending: otpProcessFx.pending,
  $completeTaskQuestionFxPenging: completeTaskQuestionFx.pending,
  $questionProcessFxPending: questionProcessFx.pending,
  getCustomerPrivacyNotice,
  setRecaptchaValue,
  $customerPrivacyNoticeDoc,
  resetPrivacyDoc,
  $customerPrivacyNoticePending: pending({
    effects: [customerPrivacyNoticeFx],
  }),
};
