import { createElement } from "react";
import ReactDOM from "react-dom";
import "~/configs/appdynamics";
import "~/i18n";

import Root from "~/app";

ReactDOM.render(createElement(Root), document.getElementById("app"));

if (process.env.REACT_APP_HOT_LOADER_ENABLED === "true" && module.hot) {
  module.hot.accept();
}
