import {
  BankSectionDto,
  BankSectionSaveRequest,
  CommonSectionDto,
  CommonSectionSaveRequest,
  DeleteDocRequest,
  DocumentCode,
  EconomySectionDto,
  EconomySectionSaveRequest,
  InvestSectionDto,
  InvestSectionSaveRequest,
  MetaDocRequests,
  ProfileDto,
  ProfileExistenceResponse,
  RequiredDocsResponse,
  UploadDocRequest,
  UploadDocResponse,
  UploadedDocDto,
} from "~/entities/anketa";
import { AppError } from "~/entities/app";

import { createAction, createAsyncActions } from "~/lib/redux";

export const [
  anketaCheckProfileExistenceRequestAction,
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaCheckProfileExistenceErrorAction,
] = createAsyncActions<ProfileExistenceResponse>("ANKETA_CHECK_PROFILE_EXISTENCE");

export const [
  anketaGetProfileRequestAction,
  anketaGetProfileStartAction,
  anketaGetProfileSuccessAction,
  anketaGetProfileErrorAction,
] = createAsyncActions<ProfileDto>("ANKETA_GET_PROFILE");

export const [
  anketaGetCommonSectionRequestAction,
  anketaGetCommonSectionStartAction,
  anketaGetCommonSectionSuccessAction,
  anketaGetCommonSectionErrorAction,
] = createAsyncActions<CommonSectionDto>("ANKETA_GET_COMMON_SECTION");

export const [
  anketaSaveCommonSectionRequestAction,
  anketaSaveCommonSectionStartAction,
  anketaSaveCommonSectionSuccessAction,
  anketaSaveCommonSectionErrorAction,
] = createAsyncActions<CommonSectionDto, CommonSectionSaveRequest>("ANKETA_SAVE_COMMON_SECTION");

export const [
  anketaUpdateCommonSectionRequestAction,
  anketaUpdateCommonSectionStartAction,
  anketaUpdateCommonSectionSuccessAction,
  anketaUpdateCommonSectionErrorAction,
] = createAsyncActions<CommonSectionDto, CommonSectionSaveRequest>("ANKETA_UPDATE_COMMON_SECTION");

export const [
  anketaGetEconomySectionRequestAction,
  anketaGetEconomySectionStartAction,
  anketaGetEconomySectionSuccessAction,
  anketaGetEconomySectionErrorAction,
] = createAsyncActions<EconomySectionDto>("ANKETA_GET_ECONOMY_SECTION");

export const [
  anketaSaveEconomySectionRequestAction,
  anketaSaveEconomySectionStartAction,
  anketaSaveEconomySectionSuccessAction,
  anketaSaveEconomySectionErrorAction,
] = createAsyncActions<EconomySectionDto, EconomySectionSaveRequest>("ANKETA_SAVE_ECONOMY_SECTION");

export const [
  anketaUpdateEconomySectionRequestAction,
  anketaUpdateEconomySectionStartAction,
  anketaUpdateEconomySectionSuccessAction,
  anketaUpdateEconomySectionErrorAction,
] = createAsyncActions<EconomySectionDto, EconomySectionSaveRequest>("ANKETA_UPDATE_ECONOMY_SECTION");

export const [
  anketaGetInvestSectionRequestAction,
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction,
] = createAsyncActions<InvestSectionDto>("ANKETA_GET_INVEST_SECTION");

export const [
  anketaSaveInvestSectionRequestAction,
  anketaSaveInvestSectionStartAction,
  anketaSaveInvestSectionSuccessAction,
  anketaSaveInvestSectionErrorAction,
] = createAsyncActions<InvestSectionDto, InvestSectionSaveRequest>("ANKETA_SAVE_INVEST_SECTION");

export const [
  anketaUpdateInvestSectionRequestAction,
  anketaUpdateInvestSectionStartAction,
  anketaUpdateInvestSectionSuccessAction,
  anketaUpdateInvestSectionErrorAction,
] = createAsyncActions<InvestSectionDto, InvestSectionSaveRequest>("ANKETA_UPDATE_INVEST_SECTION");

export const [
  anketaGetBankSectionRequestAction,
  anketaGetBankSectionStartAction,
  anketaGetBankSectionSuccessAction,
  anketaGetBankSectionErrorAction,
] = createAsyncActions<BankSectionDto>("ANKETA_GET_BANK_SECTION");

export const [
  anketaSaveBankSectionRequestAction,
  anketaSaveBankSectionStartAction,
  anketaSaveBankSectionSuccessAction,
  anketaSaveBankSectionErrorAction,
] = createAsyncActions<BankSectionDto, BankSectionSaveRequest>("ANKETA_SAVE_BANK_SECTION");

export const [
  anketaUpdateBankSectionRequestAction,
  anketaUpdateBankSectionStartAction,
  anketaUpdateBankSectionSuccessAction,
  anketaUpdateBankSectionErrorAction,
] = createAsyncActions<BankSectionDto, BankSectionSaveRequest>("ANKETA_UPDATE_BANK_SECTION");

export const [
  anketaGetRequiredDocsRequestAction,
  anketaGetRequiredDocsStartAction,
  anketaGetRequiredDocsSuccessAction,
  anketaGetRequiredDocsErrorAction,
] = createAsyncActions<RequiredDocsResponse>("ANKETA_GET_REQUIRED_DOCS");

export const [
  anketaGetUploadedDocsRequestAction,
  anketaGetUploadedDocsStartAction,
  anketaGetUploadedDocsSuccessAction,
  anketaGetUploadedDocsErrorAction,
] = createAsyncActions<UploadedDocDto>("ANKETA_GET_UPLOADED_DOCS");

export const anketaUploadDocRequestAction = createAction<UploadDocRequest>("ANKETA_UPLOAD_DOC/REQUEST");
export const anketaUploadDocStartAction = createAction<UploadDocRequest>("ANKETA_UPLOAD_DOC/START");
export const anketaUploadDocSuccessAction = createAction<UploadDocResponse, MetaDocRequests>(
  "ANKETA_UPLOAD_DOC/SUCCESS",
  (payload: UploadDocResponse): UploadDocResponse => payload,
  (_: UploadDocResponse, meta: MetaDocRequests): MetaDocRequests => meta
);
export const anketaUploadDocErrorAction = createAction<Error, MetaDocRequests>(
  "ANKETA_UPLOAD_DOC/ERROR",
  (error: Error): Error => error,
  (_: Error, meta: MetaDocRequests): MetaDocRequests => meta
);

export const anketaDeleteDocRequestAction = createAction<DeleteDocRequest>("ANKETA_DELETE_DOC/REQUEST");
export const anketaDeleteDocStartAction = createAction<DeleteDocRequest>("ANKETA_DELETE_DOC/START");
export const anketaDeleteDocSuccessAction = createAction<undefined, MetaDocRequests>(
  "ANKETA_DELETE_DOC/SUCCESS",
  (): undefined => undefined,
  (meta: MetaDocRequests): MetaDocRequests => meta
);
export const anketaDeleteDocErrorAction = createAction<Error, MetaDocRequests>(
  "ANKETA_DELETE_DOC/ERROR",
  (error: Error): Error => error,
  (_: Error, meta: MetaDocRequests): MetaDocRequests => meta
);

export const [
  anketaDownloadUplodedFileRequestAction,
  anketaDownloadUplodedFileStartAction,
  anketaDownloadUplodedFileSuccessAction,
  anketaDownloadUplodedFileErrorAction,
] = createAsyncActions<DocumentCode, string, AppError>("ANKETA_DOWNLOAD_UPLOADED_FILE");
