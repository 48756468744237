import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";
export const useStyles = makeStyles()(() => ({
  wrapIconTooltip: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    background: colorTable.lightGrey_30,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tooltip: {
    background: colorTable.charcoalBlack,
    maxWidth: "415px",
    padding: "8px 12px",
    [themeV5.breakpoints.down(768)]: {
      maxWidth: "90%",
    },
  },
  button: {
    display: "inline-block",
  },
}));
