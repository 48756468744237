import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";
import { tableFont } from "~/shared/ui-kit/constants/typography";

export default makeStyles()(() => ({
  reset: {
    margin: "0",
    padding: "0",
    outline: "none",
    border: "none",
    background: "none",
  },
  baseButton: {
    ...tableFont.subhead,
    display: "flex",
    alignItems: "center",
    gap: "0 8px",
    padding: "0 24px",
    borderRadius: "4px",
    cursor: "pointer",
    maxHeight: "48px",
    minHeight: "48px",
    justifyContent: "center",
    "& .MuiCircularProgress-circleIndeterminate ": {
      color: colorTable.charcoalBlack,
    },
  },
  default: {
    padding: 0,
    color: colorTable.charcoalBlack,
    "&:active:disabled, &:hover:disabled, &:disabled": {
      color: colorTable.moonGrey,
      cursor: "not-allowed",
    },
    maxHeight: "unset",
    minHeight: "unset",
    height: "fit-content",
  },
  white: {
    color: colorTable.charcoalBlack,
    border: `1px solid ${borderColors.greyContour}`,
    backgroundColor: colorTable.white,
    boxSizing: "border-box",
    padding: "0 22px",
    "&:active, &:hover": {
      borderColor: colorTable.moonGrey,
    },
    "&:active:disabled, &:hover:disabled, &:disabled": {
      backgroundColor: colorTable.lightGrey,
      borderColor: colorTable.greyContour,
      color: colorTable.moonGrey,
      cursor: "not-allowed",
    },
  },
  black: {
    color: colorTable.white,
    backgroundColor: colorTable.charcoalBlack,
    "&:active, &:hover": {
      backgroundColor: colorTable.anthraciteGray,
    },
    "&:active:disabled, &:hover:disabled, &:disabled": {
      color: colorTable.moonGrey,
      backgroundColor: colorTable.lightGrey,
      cursor: "not-allowed",
    },
  },
  inline: {
    padding: "0 4px",
    display: "inline-flex",
    maxHeight: "unset",
    minHeight: "unset",
    height: "fit-content",
  },
  grey: {
    padding: "6px 24px",
    color: colorTable.charcoalBlack,
    backgroundColor: colorTable.lightGrey,
    lineHeight: "20px",
    maxHeight: "unset",
    minHeight: "unset",
    "&:hover": {
      color: colorTable.nobleOchre,
    },
    [themeV5.breakpoints.down(992)]: {
      "&:hover": {
        color: colorTable.charcoalBlack,
      },
    },
    "&:active:disabled, &:hover:disabled, &:disabled": {
      color: colorTable.moonGrey,
      cursor: "not-allowed",
    },
  },
}));
