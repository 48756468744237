import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme) => ({
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: "auto",
    "&:hover": {
      backgroundColor: colorTable.transparent,
    },
    [theme.breakpoints.down(768)]: {
      padding: 0,
      height: "auto",
    },
  },
  arrow: {
    color: colorTable.lightGrey_90,
    transition: "transform 0.3s ease-out",
    [theme.breakpoints.down(768)]: {
      fontSize: "19px !important",
    },
  },
  arrowRotate: {
    transform: "rotate(180deg)",
  },
  white: {
    color: `${colorTable.white} !important`,
  },
  greyArrow: {
    color: colorTable.lightGrey_40,
  },
  black: {
    color: colorTable.blackBlue,
  },
  text: {
    width: "60px",
    position: "absolute",
    fontWeight: 500,
    fontSize: "14px",
    right: "26px",
    color: colorTable.blackDefault,
    "&:hover": {
      color: colorTable.blue_20,
    },
  },
  textWhite: {
    position: "absolute",
    color: colorTable.white,
    fontWeight: 500,
    fontSize: "11px",
  },
  changeLocaleMenuWrapper: {
    position: "absolute",
    zIndex: 1000,
    width: "148px",
    top: "71%",
    left: "-129px",
    height: "96px",
    paddingTop: "4px",
    background: colorTable.white,
    boxShadow: "0px 4px 8px rgba(31, 45, 63, 0.03), 0px 8px 32px rgba(31, 45, 63, 0.1)",
    borderRadius: "4px",
  },
  switchLocaleWrapper: {
    position: "relative",
  },
  switchLocaleText: {
    fontSize: "12px",
    color: colorTable.charcoalBlack,
    cursor: "pointer",
    height: "32px",
    paddingBottom: "8px",
    "&:hover": {
      color: colorTable.nobleOchre,
    },
  },
  switchLocaleTextWrapper: {
    padding: "12px 0 0 12px",
    "&:hover": {
      background: colorTable.lightGrey,
      cursor: "pointer",
    },
  },
  onlyText: {
    color: "black",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      fontSize: "12px !important",
    },
    "&:hover": {
      color: colorTable.nobleOchre,
    },
  },
  onlyTextActive: {
    color: colorTable.nobleOchre,
  },
}));
