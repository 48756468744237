import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useCommonSectionStyles = makeStyles()(() => ({
  titleSection: {
    marginBottom: "24px",
  },
  switchMiddle: {
    width: "100% !important",
    justifyContent: "space-between",
    [theme.breakpoints.down(768)]: {
      marginTop: "12px",
    },
  },
  switchBackground: {
    padding: "16px",
    background: colorTable.lightGrey,
    borderRadius: "4px",
  },
  mbFormBlock: {
    marginBottom: "40px",
  },
  mbExposedPersonsForm: {
    marginBottom: "26px",
  },
  wrapAddicon: {
    width: "20px",
    height: "20px",
    borderRadius: "4px",
    background: colorTable.charcoalBlack,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addIcon: {
    color: colorTable.white,
    width: "12px",
    height: "12px",
  },
  addBtn: {
    width: "fit-content",
  },
  trashIcon: {
    color: colorTable.lightGrey_90,
  },
  trashTitleWrap: {
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
    marginBottom: "16px",
    alignItems: "baseline",
  },
  endAdornmentCrs: {
    position: "absolute",
    top: "66%",
  },
}));

export const useAddressBlockStyles = makeStyles()(() => ({
  checkbox: {
    marginBottom: "16px",
  },
  checboxLast: {
    marginBottom: "40px",
  },
  formBlock: {
    paddingBottom: "0px !important",
  },
}));

export const useCRSStyles = makeStyles()(() => ({
  blockWarning: {
    marginTop: "40px",
    padding: "12px 24px",
    borderRadius: "4px",
    backgroundColor: colorTable.lightGrey_10,
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    color: colorTable.charcoalBlack,
  },
  title: {
    color: colorTable.textSecondary,
  },
  fieldsWrap: {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  },
  errorDublicate: {
    color: colorTable.red,
  },
  select: {
    "& .MuiSelect-select": {
      whiteSpace: "unset !important",
    },

    "& .MuiOutlinedInput-root": {
      maxHeight: "unset !important",
    },
  },
  checkboxLabel: {
    color: colorTable.black,
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "20px",
  },
  addCountryBtn: {
    padding: 0,
    marginRight: "21px",
  },
}));
