import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useFooterStyles = makeStyles()(() => ({
  topBlock: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "34px",
    borderBottom: `1px solid ${borderColors.greyContour}`,
    [theme.breakpoints.down(768)]: {
      justifyContent: "flex-start",
      flexDirection: "column",
      paddingBottom: "24px",
    },
  },
  contactWrapperSlim: {
    paddingBottom: "34px",
    borderBottom: `1px solid ${borderColors.greyContour}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(768)]: {
      paddingBottom: "24px",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  hoverEffect: {
    [theme.breakpoints.up(992)]: {
      "&:hover": {
        color: colorTable.nobleOchre,
      },
    },
  },
  phoneWrapper: {
    textAlign: "right",
    [theme.breakpoints.down(768)]: {
      textAlign: "left",
      marginTop: "8px",
    },
  },
  phone: {
    color: colorTable.charcoalBlack,
    fontSize: "24px",
    lineHeight: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    textDecoration: "none",
    marginBottom: "11px",
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  operatingMode: {
    color: colorTable.moonGrey,
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    marginTop: "10px",
  },
  email: {
    color: colorTable.charcoalBlack,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    textDecoration: "none",
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  menuItem: {
    color: colorTable.charcoalBlack,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingBottom: "16px",
    cursor: "pointer",
    "&:last-of-type": {
      marginBottom: "0",
    },
  },
  middleBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "32px 0",
    borderBottom: `1px solid ${borderColors.greyContour}`,
    [theme.breakpoints.down(768)]: {
      padding: "24px 0",
      justifyContent: "flex-start",
      flexDirection: "column",
      "& > div": {
        marginBottom: "24px",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
  },
  socialTitle: {
    color: colorTable.textSecondary,
    fontSize: "14px",
    lineHeight: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "0 0 6px 0",
  },
  applicationWrapper: {
    textAlign: "left",
  },
  socialsWrapper: {
    textAlign: "center",
    [theme.breakpoints.down(768)]: {
      textAlign: "left",
    },
  },
  emailWrapper: {
    textAlign: "right",
    [theme.breakpoints.down(768)]: {
      textAlign: "left",
    },
  },
  socialList: {
    "& a": {
      marginRight: "24px",
      "&:last-of-type": {
        marginRight: "0",
      },
    },
  },
  applicationsList: {
    "& a": {
      marginRight: "24px",
      "&:last-of-type": {
        marginRight: "0",
      },
    },
  },
  bottomBlock: {
    padding: "32px 0",
    borderBottom: `1px solid ${borderColors.greyContour}`,
    [theme.breakpoints.down(768)]: {
      padding: "24px 0",
      display: "flex",
      flexDirection: "column",
    },
  },
  link: {
    color: colorTable.charcoalBlack,
    fontSize: "16px",
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    marginRight: "24px",
    textDecoration: "none",
    "&:last-of-type": {
      marginRight: "0",
    },
    [theme.breakpoints.down(768)]: {
      marginBottom: "11px",
    },
  },
  underFooterTextBlock: {
    padding: "32px 0",
    [theme.breakpoints.down(768)]: {
      padding: "24px 0",
    },
  },
  footerText: {
    color: colorTable.moonGrey,
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom: "32px",
    "& b": {
      fontWeight: 400,
    },
  },
  footerLink: {
    "&>div": {
      display: "inline",
    },
    "& svg": {
      [theme.breakpoints.up(992)]: {
        "&:hover": {
          color: colorTable.nobleOchre,
        },
      },
    },
  },
}));
