import React, { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, ButtonProps, ClickAwayListener, Typography } from "@mui/material";
import cn from "classnames";
import { useStore } from "effector-react";

import { colors } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import * as storage from "~/lib/storage";
import { headerModel } from "~/modules/header-model";
import Icon from "~/shared/Icon";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { useStyles } from "./styles";

export interface SwitchLocaleProps extends ButtonProps {
  children?: never;
  className?: string;
  colorText?: colors.white | colors.black;
  sizeIcon?: 20 | 150 | 100 | 70 | 56 | 50 | 40 | 32 | 30 | 27 | 8;
  colorImg?: colors.white | colors.black;
  withoutIcon?: boolean;
}

const SwitchLocaleViewport = ({
  className,
  colorText = colors.black,
  sizeIcon = 8,
  colorImg = colors.black,
  withoutIcon = false,
  ...restProps
}: SwitchLocaleProps) => {
  const { classes } = useStyles();
  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  const { t, i18n } = useTranslation();

  const [showSwitchLocale, setShowSwitchLocale] = useState<boolean>(false);

  const toggle = () => {
    setShowSwitchLocale(!showSwitchLocale);
    i18n.changeLanguage("ru");
    return storage.set("d_locale", "ru");
  };

  const toggle2 = () => {
    setShowSwitchLocale(!showSwitchLocale);
    i18n.changeLanguage("en");
    return storage.set("d_locale", "en");
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setShowSwitchLocale(false)}
    >
      <Box className={classes.switchLocaleWrapper}>
        <BaseButton
          typeStyle="inline"
          className={cn(classes.button, classes[colorText], className)}
          variant="text"
          onClick={() => setShowSwitchLocale(!showSwitchLocale)}
          disableRipple
          {...restProps}
        >
          {colorImg === colors.white ? (
            <Box style={{ background: colors.white, borderRadius: "50%", width: sizeIcon, height: sizeIcon }} />
          ) : (
            !withoutIcon && <Icon size={sizeIcon} name="changeLang" />
          )}
          <BaseTypography
            tag="p"
            fontType="subhead"
            className={cn(
              withoutIcon ? classes.onlyText : classes.textWhite,
              {
                [classes.onlyTextActive]: showSwitchLocale,
              },
              { [classes.white]: headerTheme.isDarkColorTheme }
            )}
          >
            {i18n.language === "ru" ? "RU" : "EN"}
          </BaseTypography>
          {withoutIcon && colorImg !== "white" && (
            <KeyboardArrowDown
              fontSize="small"
              className={cn(
                classes.arrow,
                { [classes.arrowRotate]: showSwitchLocale },
                { [classes.greyArrow]: headerTheme.isDarkColorTheme }
              )}
            />
          )}
        </BaseButton>
        {showSwitchLocale && (
          <Box className={classes.changeLocaleMenuWrapper}>
            <Box onClick={toggle} className={classes.switchLocaleTextWrapper}>
              <Typography className={classes.switchLocaleText}>{t("I18N.russian-language")}</Typography>
            </Box>
            <Box onClick={toggle2} className={classes.switchLocaleTextWrapper}>
              <Typography className={classes.switchLocaleText}>{t("I18N.english-language")}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SwitchLocaleViewport;
