import { Domain, Store } from "effector";
import { createForm } from "effector-forms";
import moment, { Moment } from "moment";

import { countryEnum, dateTypes, noTaxReasons } from "~/constants/enums";
import {
  apartmentRules,
  buildingRules,
  cityRules,
  houseRules,
  required,
  rules,
  stateRules,
  streetRules,
  zipCodeRules,
} from "~/lib/validation/rules";
import { Country } from "~/pages/OpenAssetManagement/services/types";
import { newDateRules } from "./rules";

export const createClientIoPepInfoForm = (domain: Domain, booleanSource?: Store<boolean>) =>
  createForm({
    domain,
    fields: {
      pepFunction: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPeriodStart: {
        init: null as Date | null,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.lessToday(booleanSource),
          newDateRules.minDate(booleanSource),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodEnd && date) {
                const valid = moment(date).isBefore(
                  moment(form.pepPeriodEnd).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.valid_period",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth",
          },
        ],
      },
      pepPeriodEnd: {
        init: null as Date | null,
        rules: [
          newDateRules.minDate(booleanSource),
          {
            name: "period end less period start",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodStart && date) {
                const valid = moment(date).isAfter(
                  moment(form.pepPeriodStart).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.dateEnd_more_dateStart",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_end",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });

export const createIoPepInfoForm = (domain: Domain, booleanSource?: Store<boolean>) =>
  createForm({
    domain: domain,
    fields: {
      pepFirstName: {
        init: "",
        rules: [rules.required(booleanSource), rules.trueName(), rules.less(50)], // Нужна проверка, т.к. в карте клиента могут приходить кириллические символы, а они запрещены только на ввод
      },
      pepLastName: {
        init: "",
        rules: [rules.required(booleanSource), rules.trueName(), rules.less(50)],
      },
      pepMiddleName: {
        init: "",
        rules: [rules.trueName(), rules.less(50)],
      },
      relationship: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50), rules.latinOnly(booleanSource)],
      },
      pepPassSeries: {
        init: "",
        rules: [rules.less(50)],
      },
      pepPassNumber: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPassIssuedWhen: {
        init: null as Date | null,
        rules: [
          newDateRules.required(),
          newDateRules.lessToday(),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_pass",
          },
        ],
      },
      pepBirthDay: {
        init: null as Date | null,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.minDate(booleanSource),
          newDateRules.lessToday(booleanSource),
        ],
      },
      pepPlaceOfBirth: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(100), rules.city(booleanSource)],
      },
      pepBirthCountryCode: {
        init: "",
        rules: [rules.required(booleanSource)],
      },
      pepFunction: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPeriodStart: {
        init: null as Date | null,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.lessToday(booleanSource),
          newDateRules.minDate(booleanSource),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodEnd && date) {
                const valid = moment(date).isBefore(
                  moment(form.pepPeriodEnd).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.valid_period",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth",
          },
        ],
      },
      pepPeriodEnd: {
        init: null as Date | null,
        rules: [
          newDateRules.minDate(booleanSource),
          {
            name: "period end less period start",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodStart && date) {
                const valid = moment(date).isAfter(
                  moment(form.pepPeriodStart).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.dateEnd_more_dateStart",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_end",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });

export const createAddressForm = (type: string, domain: any, validation: any): any => {
  return createForm({
    domain: domain,
    fields: {
      country: {
        init: "",
        rules: [rules.required(validation)],
      },
      city: {
        init: "",
        rules: cityRules(validation, required),
      },
      type: {
        init: type,
      },
      state: {
        init: "",
        rules: stateRules(validation, required),
      },
      street: {
        init: "",
        rules: streetRules(validation, required),
      },
      house: {
        init: "",
        rules: houseRules(validation, required),
      },
      building: {
        init: "",
        rules: buildingRules(validation),
      },
      apartment: {
        init: "",
        rules: apartmentRules(validation),
      },
      zipcode: {
        init: "",
        rules: zipCodeRules(validation, required),
      },
    },
    validateOn: ["submit"],
  });
};

export const documentForm = (type: string, domain: any, validation: any) => {
  return createForm({
    domain: domain,
    fields: {
      type: {
        init: type,
      },
      number: {
        init: "",
      },
      serial: {
        init: "",
      },
      issueDate: {
        init: "",
      },
      expireDate: {
        init: "",
      },
      issuedBy: {
        init: "",
        rules: [
          {
            name: "requrired",
            validator: (val, form) => (form.number && validation ? !!val : true),
            errorText: "VALIDATION.required-field",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });
};

export const scrollToMyRef = (ref: any) => {
  if (ref) {
    window.scrollTo(0, ref?.current?.offsetParent?.offsetTop);
  }
};

export const formatDateToDTO = (date: string | Moment | null): string =>
  date ? moment(date).format(dateTypes.EN_DATE) : "";

export const formatDTOToDate = (date: string | null): Date | null =>
  date ? moment(date, dateTypes.EN_DATE).toDate() : null;

export const booleanArrayToSelect = (values: boolean[] | null): number | null => {
  if (values) {
    const index = values.findIndex((value) => value);
    if (index >= 0) return index;
    else return null;
  }
  return null;
};

export const changeBooleanArray = (arr: boolean[], itemsCount: number): boolean[] => {
  const templateArray = Array(itemsCount)
    .fill(false)
    .map((item, index) => {
      if (arr[index] === true) {
        return arr[index];
      }
      return item;
    });
  return templateArray;
};

export const selectToBooleanArray = (selectedValue: any, itemsCount: number): boolean[] => {
  const templateArray = Array(itemsCount).fill(false);
  templateArray[selectedValue] = true;
  return templateArray;
};

export const substringFrom = (str: string, fromString: string) => {
  const from = str.search(fromString) + fromString.length;
  const to = str.length;
  return str.substring(from, to);
};

type NoTinReason = {
  value: string;
  reasonType: string;
};

export const noTinReasons: NoTinReason[] = [
  { reasonType: "A", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonA" },
  { reasonType: "B", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonB" },
  { reasonType: "C", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonC" },
];

export const createCrsInfoForm = (domain: Domain) =>
  createForm({
    domain: domain,
    fields: {
      taxResidencyCountry: {
        init: "",
        rules: [rules.required()],
      },
      tinNumber: {
        init: "",
        rules: [
          {
            name: "requrired",
            validator: (val, form) => (form.isTaxResident ? !!val : true),
            errorText: "VALIDATION.required-field",
          },
          rules.less(50),
        ],
      },
      isTaxResident: {
        init: true,
      },
      noTinReason: {
        init: "",
        rules: [
          {
            name: "required",
            validator: (val: string, form) => (!form.isTaxResident ? !!val : true),
            errorText: "OPEN_ACCOUNT.errors.select_answer",
          },
        ],
      },
      noTinReasonDesc: {
        init: "",
        rules: [
          // rules.lessIf(300),
          {
            name: "required_if_no_tin_reason_desc",
            validator: (value, form) => {
              if (form.noTinReason !== noTaxReasons[1].reason) return true;
              return Boolean(value);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
        validateOn: ["submit"],
      },
    },
  });

export const createIDUForm = (domain: Domain) => {
  const form = createForm({
    domain: domain,
    fields: {
      investMeans: {
        init: "",
        rules: [
          {
            name: "investMeans not empty",
            validator: (value: string) => value.trim() !== "",
          },
        ],
      },
      strategyRelatedOptions: {
        init: "",
        rules: [
          {
            name: "strategyRelatedOptions not empty",
            validator: (value: string) => value.trim() !== "",
          },
        ],
      },
      investInstruments: {
        init: "",
        rules: [
          {
            name: "investInstruments not empty",
            validator: (value: string) => value.trim() !== "",
          },
        ],
      },
      investTerm: {
        init: "",
        rules: [
          {
            name: "investTerm not empty",
            validator: (value: string) => value.trim() !== "",
          },
        ],
      },
      strategies: {
        init: null,
      },
    },
    validateOn: ["change"],
  });
  return form;
};

export const getCrsValuesToDTO = (forms: CrsInfoForm[]) => {
  return forms.map((form) => {
    const formData = form.$values.getState();
    return {
      tinNumber: formData.tinNumber?.trim() || null,
      isTaxResident: formData.isTaxResident || false,
      noTinReason: formData.noTinReason || null,
      taxResidencyCountry: formData.taxResidencyCountry,
    };
  });
};

export const checkSameForms = (forms: CrsInfoForm[]): number[] => {
  const indexes = [];
  const formsValues = forms.map((form) => form.$values.getState());
  for (let i = 0; i < formsValues.length; i++) {
    for (let j = 0; j < formsValues.length; j++) {
      if (i !== j && formsValues[i].taxResidencyCountry === formsValues[j].taxResidencyCountry) {
        if (
          !formsValues[i].isTaxResident &&
          !formsValues[j].isTaxResident &&
          formsValues[i].tinNumber !== "" &&
          formsValues[i].tinNumber === formsValues[j].tinNumber
        )
          indexes.push(i);
        else if (formsValues[i].isTaxResident && formsValues[j].isTaxResident) {
          if (
            (formsValues[i].noTinReason === formsValues[j].noTinReason &&
              formsValues[i].noTinReason !== "" &&
              formsValues[i].noTinReason !== "ACCOUNT_OWNER_CANNOT_GET_TIN") ||
            (formsValues[i].noTinReason === "ACCOUNT_OWNER_CANNOT_GET_TIN" &&
              formsValues[j].noTinReason === "ACCOUNT_OWNER_CANNOT_GET_TIN")
          )
            indexes.push(i);
        }
      }
    }
  }
  return indexes;
};

export const formatCountrySearch = (countries: Country[]): Country[] => {
  const rusElem = countries.find((country) => country.codeIso === countryEnum.RF);
  const areElem = countries.find((country) => country.codeIso === countryEnum.ARE);

  if (rusElem && areElem) {
    const filtredCountries = countries
      .filter((country) => country.codeIso !== countryEnum.RF && country.codeIso !== countryEnum.ARE)
      .sort((a, b) => (a.englishName > b.englishName ? 1 : -1));
    filtredCountries.unshift(areElem, rusElem);
    return filtredCountries;
  }
  return countries.sort((a, b) => (a.englishName > b.englishName ? 1 : -1));
};

export type CrsInfoForm = ReturnType<typeof createCrsInfoForm>;

export type IoPepInfoForm = ReturnType<typeof createIoPepInfoForm>;
export type IoClientPepInfoForm = ReturnType<typeof createClientIoPepInfoForm>;
