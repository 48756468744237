import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import TextField, { TextFieldProps } from "~/shared/ui/TextField";

const useStyles = makeStyles()((theme) => ({
  endBtn: {
    [theme.breakpoints.down(768)]: {
      padding: "7px",
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
  },
}));

export type PasswordFieldProps = Omit<TextFieldProps, "type">;

const opacity = { opacity: 0.5 };
const inputProps = { autoComplete: "off" };

const PasswordField = (props: PasswordFieldProps) => {
  const { defaultVisibleValue, ...textFieldProps } = props;

  const [showPassword, changeShowPassword] = useState<boolean>(!!defaultVisibleValue ? defaultVisibleValue : false);
  const { classes } = useStyles();

  return (
    <TextField
      {...textFieldProps}
      type={showPassword ? "text" : "password"}
      inputProps={inputProps}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              className={classes.endBtn}
              aria-label="Toggle password visibility"
              onClick={(): void => changeShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility style={opacity} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
