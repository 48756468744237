import React, { MouseEvent, useCallback, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import cn from "classnames";
import { useStore } from "effector-react";

import { useUserDropdownStyles } from "~/app/layouts/BaseLayout/components/ViewportHeader/components/UserDropdownButton/styles";
import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { currentClientModel } from "~/modules/current-client-model";
import { headerModel } from "~/modules/header-model";
import { logoutModel } from "~/modules/keycloak/logout-model";
import Icon from "~/shared/Icon";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";

const UserDropdownButton = () => {
  const { navigate } = useRouterHooks();
  const { classes } = useUserDropdownStyles();
  const { $currentClient, $getCurrentClientPending } = currentClientModel;
  const currentClient = useStore($currentClient);
  const getCurrentClientPending = useStore($getCurrentClientPending);
  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  const clientName = `${currentClient?.fullName.split(" ")[0]} ${
    currentClient?.fullName.split(" ")[1] ? currentClient?.fullName.split(" ")[1][0] + "." : ""
  }`;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
      setOpenMenu((prev) => !prev);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
    setOpenMenu((prev) => !prev);
  }, [setAnchorEl]);

  const handleShowProfile = useCallback((): void => {
    setAnchorEl(null);
    navigate(links.profile);
    setOpenMenu((prev) => !prev);
  }, [setAnchorEl, navigate]);

  const onLogout = () => {
    sessionStorage.removeItem("redirectPath");
    logoutModel.logout();
    setOpenMenu((prev) => !prev);
  };

  if (getCurrentClientPending) {
    return <CircularProgress size={20} thickness={5} />;
  }

  return (
    <>
      <BaseButton
        className={classes.btnClient}
        typeStyle="inline"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon className={classes.icon} name="minimalGreyUser" size={18} />
        <div
          className={cn(
            classes.name,
            { [classes.active]: openMenu },
            { [classes.whiteText]: headerTheme.isDarkColorTheme }
          )}
        >
          {clientName}
        </div>
        <KeyboardArrowDown
          fontSize="small"
          className={cn(
            classes.arrow,
            { [classes.arrowRotate]: openMenu },
            { [classes.greyArrow]: headerTheme.isDarkColorTheme }
          )}
        />
      </BaseButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock
        MenuListProps={{ className: classes.menu }}
      >
        <MenuItem onClick={handleShowProfile} className={classes.menuItem}>
          <Icon name="userProfile" className={classes.iconBox} size={24} />
          {t("MENU.USER.profile")}
        </MenuItem>

        <MenuItem onClick={onLogout} className={classes.menuItem}>
          <Icon name="mainExit" className={classes.iconBox} size={24} />
          {t("MENU.USER.exit")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdownButton;
