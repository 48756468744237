import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useUserDropdownStyles = makeStyles()((theme: Theme) => ({
  icon: {
    marginRight: "8px",
  },
  name: {
    overflow: "hidden",
    color: colorTable.charcoalBlack,
    fontSize: "14px",
    lineHeight: "18px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textTransform: "none",
    [theme.breakpoints.up(991)]: {
      "&:hover": {
        color: colorTable.nobleOchre,
      },
    },
  },
  active: {
    color: colorTable.nobleOchre,
  },
  whiteText: {
    color: colorTable.white,
  },
  arrow: {
    color: colorTable.lightGrey_90,
    transition: "transform 0.3s ease-out",
    [theme.breakpoints.down(768)]: {
      fontSize: "19px !important",
    },
  },
  arrowRotate: {
    transform: "rotate(180deg)",
  },
  greyArrow: {
    color: `${colorTable.lightGrey_40} !important`,
  },
  line: {
    height: "3px",
    width: "100%",
    marginTop: "-8px",
    backgroundColor: colorTable.blue_50,
  },
  btnClient: {
    gap: "0!important",
  },
  iconBox: {
    marginRight: "15px",
  },
  menu: {
    border: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "&.MuiMenu-list li::after": {
      background: colorTable.greyContour,
    },
  },
  menuItem: {
    minWidth: "fit-content",
    paddingRight: "12px!important",

    "&:hover": {
      background: colorTable.lightGrey,
      color: colorTable.nobleOchre,
    },
  },
}));
