import { createAction, createAsyncActions } from '~/lib/redux';

import { Commission, BuyProductResult, BuyingDocument } from '~/entities/product';

export const [
  getProductCommissionRequestAction,
  getProductCommissionStartAction,
  getProductCommissionSuccessAction,
  getProductCommissionErrorAction,
] = createAsyncActions<Commission, { agreementNumber: string; investmentSum: number }>('PRODUCT_COMMISSION');

export const [
  buyProductRequestAction,
  buyProductStartAction,
  buyProductSuccessAction,
  buyProductErrorAction,
  buyProductClearAction,
] = createAsyncActions<
  { applicationId: BuyProductResult; printForms: BuyingDocument[] },
  { agreementNumber: string; investmentSum: number }
>('PRODUCT_BUY');

export const productClearAction = createAction('PRODUCT_CLEAR');

export const signProductRequestAction = createAction<{ requestId: number }>('PRODUCT_SIGN');

export const resendSMSProductRequestAction = createAction<{ requestId: number }>('PRODUCT_SMS_RESEND');

export const checkSMSProductRequestAction = createAction<{ requestId: number; otp: string }>('PRODUCT_SMS_CHECK');

export const saveProductPrintForms = createAction<{ data: string; filename: string; isBase64: boolean }[]>(
  'PRODUCT_SAVE_PRINT_FORMS',
);
