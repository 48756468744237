import { createAsyncActions } from '~/lib/redux';

import {
  KeycloakChangePasswordModel,
  KeycloakChangeSecurityQuestionModel,
  KeycloakOtpCheckRequestModel,
  KeycloakResetPasswordModel,
  KeycloakResponseModel,
  KeycloakResponseModelCheckOtpContextMeta,
  KeycloakResponseModelSecurityQuestionUserNameMeta,
} from '~/entities/keycloak';
import { KeycloakInitialCredentialsModel } from '~/entities/keycloak/models/KeycloakInitialCredentialsModel';
import { KeycloakSecurityQuestionsModel } from '~/entities/keycloak/models/KeycloakSecurityQuestionsModel';

export const [
  keycloakResetPasswordRequestAction,
  keycloakResetPasswordStartAction,
  keycloakResetPasswordSuccessAction,
  keycloakResetPasswordErrorAction,
  keycloakResetPasswordClearAction,
] = createAsyncActions<KeycloakResponseModel, KeycloakResetPasswordModel>('KEYCLOAK_RESET_PASSWORD');

export const [
  keycloakInitialCredentialsRequestAction,
  keycloakInitialCredentialsStartAction,
  keycloakInitialCredentialsSuccessAction,
  keycloakInitialCredentialsErrorAction,
  keycloakInitialCredentialsClearAction,
] = createAsyncActions<KeycloakResponseModel, KeycloakInitialCredentialsModel>('KEYCLOAK_INITIAL_CREDENTIALS');

export const [
  keycloakChangePasswordRequestAction,
  keycloakChangePasswordStartAction,
  keycloakChangePasswordSuccessAction,
  keycloakChangePasswordErrorAction,
  keycloakChangePasswordClearAction,
] = createAsyncActions<KeycloakResponseModel, KeycloakChangePasswordModel>('KEYCLOAK_CHANGE_PASSWORD');

export const [
  keycloakCheckOtpRequestAction,
  keycloakCheckOtpStartAction,
  keycloakCheckOtpSuccessAction,
  keycloakCheckOtpErrorAction,
  keycloakCheckOtpClearAction,
] = createAsyncActions<
  KeycloakResponseModel & KeycloakResponseModelCheckOtpContextMeta,
  KeycloakOtpCheckRequestModel & KeycloakResponseModelCheckOtpContextMeta,
  Error,
  KeycloakResponseModelCheckOtpContextMeta
>('KEYCLOAK_CHECK_OTP', ({ context }) => ({ context }));

export const [
  keycloakSecurityQuestionsRequestAction,
  keycloakSecurityQuestionsStartAction,
  keycloakSecurityQuestionsSuccessAction,
  keycloakSecurityQuestionsErrorAction,
] = createAsyncActions<KeycloakSecurityQuestionsModel>('KEYCLOAK_SECURITY_QUESTIONS');

export const [
  keycloakSecurityQuestionRequestAction,
  keycloakSecurityQuestionStartAction,
  keycloakSecurityQuestionSuccessAction,
  keycloakSecurityQuestionErrorAction,
  keycloakSecurityQuestionClearAction,
] = createAsyncActions<
  KeycloakResponseModel & KeycloakResponseModelSecurityQuestionUserNameMeta,
  KeycloakResponseModelSecurityQuestionUserNameMeta,
  Error,
  KeycloakResponseModelSecurityQuestionUserNameMeta
>('KEYCLOAK_SECURITY_QUESTION', ({ userName }) => ({
  userName,
}));

export const [
  keycloakChangeSecurityQuestionRequestAction,
  keycloakChangeSecurityQuestionStartAction,
  keycloakChangeSecurityQuestionSuccessAction,
  keycloakChangeSecurityQuestionErrorAction,
  keycloakChangeSecurityQuestionClearAction,
] = createAsyncActions<KeycloakResponseModel, KeycloakChangeSecurityQuestionModel>(
  'KEYCLOAK_CHANGE_SECURITY_QUESTIONS',
);
