import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minHeight: "100%",
    position: "relative",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  pageTitle: {
    fontSize: "36px",
    lineHeight: "42px",
    fontWeight: 600,
    marginTop: "36px",
    marginBottom: "24px",
    color: colorTable.charcoalBlack,
    [theme.breakpoints.down(768)]: {
      fontSize: "24px",
      lineHeight: "28px",
      marginTop: "20px",
      textAlign: "center",
    },
  },
  container: {
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "center",
    flexGrow: 1,
    padding: "20px 10px 56px 10px",
    alignItems: "center",
    zIndex: 3,
    background: colorTable.lightGrey_10,
    borderRadius: "24px",
    flexDirection: "column",
    width: "calc(100% - 160px)",
    margin: "25px auto 0 auto",
    [theme.breakpoints.down(1200)]: {
      width: "calc(100% - 80px)",
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
      padding: "20px 0 56px 0",
      margin: "12px auto 0 auto",
      borderRadius: "0",
    },
  },
  container2: {
    display: "flex",
    height: "100%",
    background: colorTable.white,
    borderRadius: "24px",
    justifyContent: "center",
    minHeight: "545px",
    width: "100%",
    padding: "0 24px",
    maxWidth: "950px",
    [theme.breakpoints.down(768)]: {
      padding: "0 16px",
      minHeight: "auto",
    },
  },
  backgroundImgWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(14,20,26)",
  },
  backgroundImg: {
    position: "absolute",
    right: 0,
    height: "100%",
  },
  layout: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    opacity: 0.3,
    zIndex: 2,
  },
  containerMobile: {
    width: "100%",
    maxWidth: "none",
  },
  footerWrapper: {
    width: "100%",
    zIndex: 10,
    paddingTop: "40px",
  },
}));
