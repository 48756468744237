import React, { FunctionComponent, ReactElement, SVGProps, useState } from "react";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import Accomplished from "./icons/accomplished.svg";
import AccordionArrow from "./icons/accordion_arrow.svg";
import AccordionArrowWhite from "./icons/accordion_arrow_white.svg";
import AccountInvestment from "./icons/account_investment.svg";
import AccountAlreadyRegistered from "./icons/AccountAlreadyRegistered.svg";
import AccountCardIcon from "./icons/accountCard.svg";
import AddCountry from "./icons/addCountry.svg";
import AddIcon from "./icons/addIcon.svg";
import Alto from "./icons/alto.svg";
import ArrowDownwardRoundedSvg from "./icons/arrow-downward-rounded.svg";
import AuthError from "./icons/authError.svg";
import BackButton from "./icons/backButton.svg";
import Bag from "./icons/bag.svg";
import BankIconMinimalActive from "./icons/bank_active.svg";
import BankSection from "./icons/bankSection.svg";
import Bell from "./icons/bell.svg";
import BellGrey from "./icons/bell_grey.svg";
import BlackCircle from "./icons/BlackCircle.svg";
import BlockAccount from "./icons/blockAccount.svg";
import Bonds from "./icons/bonds.svg";
import Building from "./icons/building.svg";
import BurgerMinimalButton from "./icons/Burger_menu.svg";
import BurgerMinimalWhiteButton from "./icons/burger_menu_white.svg";
import ButtonPlus from "./icons/button_plus.svg";
import Calculator from "./icons/calculator.svg";
import Candles from "./icons/candles.svg";
import ChangeLangSvg from "./icons/changeLang.svg";
import Pie from "./icons/chart_pie.svg";
import Charts from "./icons/charts.svg";
import Check_details from "./icons/check_details.svg";
import Checkbox from "./icons/checkbox.svg";
import CheckboxBlack from "./icons/checkbox_black.svg";
import CheckboxGreen from "./icons/checkbox_green.svg";
import CheckboxDisable from "./icons/checkboxDisable.svg";
import CheckboxRed from "./icons/checkboxRed.svg";
import CheckedCheckbox from "./icons/checkedCheckbox.svg";
import CheckedCheckboxDisable from "./icons/checkedCheckboxDisable.svg";
import ChevronAccordion from "./icons/chevronAccordion.svg";
import ChevronLeft from "./icons/ChevronLeft.svg";
import CircleIcon1 from "./icons/circle_icon_1.svg";
import CircleIcon2 from "./icons/circle_icon_2.svg";
import CircleIcon3 from "./icons/circle_icon_3.svg";
import CircleIcon4 from "./icons/circle_icon_4.svg";
import CircleIcon5 from "./icons/circle_icon_5.svg";
import CircleIcon6 from "./icons/circle_icon_6.svg";
import StockIconCisco from "./icons/Cisco_Systems_Inc.svg";
import Clear from "./icons/clear.svg";
import Close from "./icons/close.svg";
import Close2 from "./icons/close2.svg";
import StockIconCocaCola from "./icons/Coca-Cola_Company.svg";
import ConfirmationDocs from "./icons/ConfirmationDocs.svg";
import StockIconConstellation from "./icons/Constellation_Brands_Inc.svg";
import ContactUs2 from "./icons/contactUs2.svg";
import ContactUs2_white from "./icons/contactUs2_white.svg";
import ContractPlus from "./icons/contractPlus.svg";
import Coupon from "./icons/coupon.svg";
import CrossStandart from "./icons/cross_standart.svg";
import CrownGold from "./icons/Crown_gold.svg";
import CrownGrey from "./icons/crown_grey.svg";
import CrownWhite from "./icons/Crown_slider_icon.svg";
import AssetsCNY from "./icons/currency/cny.svg";
import AssetsDirham from "./icons/currency/dirham.svg";
import AssetsDollar from "./icons/currency/dollar.svg";
import AssetsEuro from "./icons/currency/euro.svg";
import AssetsRub from "./icons/currency/rub.svg";
import MinimalCny from "./icons/currency_CNY.svg";
import MinimalDh from "./icons/currency_dh.svg";
import MinimalEur from "./icons/currency_euro.svg";
import MinimalRub from "./icons/currency_рубль.svg";
import CurrrencyCny from "./icons/currrencyCny.svg";
import CurrrencyEur from "./icons/currrencyEur.svg";
import CurrrencyRub from "./icons/currrencyRub.svg";
import CurrrencyUsd from "./icons/currrencyUsd.svg";
import StockIconCVSHealth from "./icons/CVS_Health_Corporation.svg";
import DateCalendar from "./icons/dateCalendar.svg";
import DateCalendaBlack from "./icons/dateCalendarBlack.svg";
import DefaultProduct from "./icons/default_product.svg";
import DeleteTrashIcon from "./icons/deleteTrashIcon.svg";
import DisabledDownload from "./icons/disabledDownload.svg";
import Docs from "./icons/docs.svg";
import DocsBlack from "./icons/docs_black.svg";
import DocumentIcon from "./icons/documentIcon.svg";
import DownloadDoc from "./icons/Documents_PDF.svg";
import DocumentsIcon from "./icons/documentsIcon.svg";
import DollarCoin from "./icons/dollar_coin.svg";
import Dot from "./icons/dot.svg";
import DoubleArrow from "./icons/double_arrow.svg";
import DoubleTriangle from "./icons/double_triangle.svg";
import Download from "./icons/download.svg";
import DownloadCircle from "./icons/downloadCircle.svg";
import DownloadIconBlack from "./icons/downloadIconBlack.svg";
import DownloadSuccess from "./icons/downloadSuccess.svg";
import Education from "./icons/education.svg";
import ErrorSquareIcon from "./icons/error_square_icon.svg";
import Exclamation from "./icons/exclamation.svg";
import ExitBlack from "./icons/exitBlack.svg";
import StarEmpty from "./icons/Favorites.svg";
import Filters from "./icons/filters.svg";
import Fire from "./icons/fire.svg";
import Flag from "./icons/flag.svg";
import Flash from "./icons/flash.svg";
import FlashII from "./icons/flashII.svg";
import ForYouCircleBig from "./icons/for_you_big_icon.svg";
import Forward from "./icons/forward.svg";
import Fund3Icon from "./icons/fund3Icon.svg";
import Fund5Icon from "./icons/fund5Icon.svg";
import Global from "./icons/global.svg";
import GlobalBondsIcon from "./icons/globalBondsIcon.svg";
import GlobalEquitiens from "./icons/globalEquitiens.svg";
import GoForwardSvg from "./icons/goForward.svg";
import GoldStar from "./icons/gold_star.svg";
import GrayCircle from "./icons/GrayCircle.svg";
import GreyCross from "./icons/greyCross.svg";
import HouseGray from "./icons/house_gray.svg";
import I from "./icons/i.svg";
import MinimalGreyUserIcon from "./icons/icon_grey_profile.svg";
import IdentifyIcon from "./icons/identify.svg";
import IdentityDoc from "./icons/IdentityDoc.svg";
import IncomeWallet from "./icons/income_wallet.svg";
import IncomeFund3 from "./icons/incomefund3.svg";
import StockIconIncyte from "./icons/Incyte_Corporation.svg";
import Info from "./icons/info.svg";
import InformationGrey from "./icons/Information_grey.svg";
import InfoIcon from "./icons/Information_light.svg";
import Knight from "./icons/knight.svg";
import Lenta from "./icons/lenta.svg";
import Like from "./icons/like.svg";
import LikeFilled from "./icons/like_filled.svg";
import LikeFilter from "./icons/like_filter.svg";
import LikeFilterFilled from "./icons/like_filter_filled.svg";
import Lock from "./icons/lock.svg";
import LockProfessional from "./icons/lock_professional.svg";
import Magnifire2 from "./icons/magnifire2.svg";
import MainExit from "./icons/mainExit.svg";
import MarginTrade from "./icons/margin_trade.svg";
import MenuDocuments from "./icons/menu/menu_documents.svg";
import MenuForYou from "./icons/menu/menu_for_you.svg";
import MenuOperations from "./icons/menu/menu_operations.svg";
import MenuProfile from "./icons/menu/menu_profile.svg";
import MenuServices from "./icons/menu/menu_services.svg";
import MessageIcon from "./icons/messageIcon.svg";
import StockIconMicrosoft from "./icons/Microsoft.svg";
import MinimalDollar from "./icons/minimalDollarIcon.svg";
import MinusButton from "./icons/minusButton.svg";
import ModalCross from "./icons/modal_cross.svg";
import ModalCrossNew from "./icons/modalCrossNew.svg";
import Note from "./icons/note.svg";
import Note24 from "./icons/note_24.svg";
import NotesPending from "./icons/notesPending.svg";
import NotSecurityQuestionIcon from "./icons/NotSecurityQuestionIcon.svg";
import NumbersRound1 from "./icons/numbers/numbers_round_1.svg";
import NumbersRound2 from "./icons/numbers/numbers_round_2.svg";
import NumbersRound3 from "./icons/numbers/numbers_round_3.svg";
import NumbersRound4 from "./icons/numbers/numbers_round_4.svg";
import NumbersRound5 from "./icons/numbers/numbers_round_5.svg";
import NumbersRound6 from "./icons/numbers/numbers_round_6.svg";
import NumbersRound7 from "./icons/numbers/numbers_round_7.svg";
import NumbersRound8 from "./icons/numbers/numbers_round_8.svg";
import NumbersRound9 from "./icons/numbers/numbers_round_9.svg";
import Okta from "./icons/okta.svg";
import DepositDarkGrey from "./icons/operations/deposit.svg";
import NoReports from "./icons/operations/no-reports.svg";
import WithdrawDarkGrey from "./icons/operations/withdrawal.svg";
import OutcomeWallet from "./icons/outcome_wallet.svg";
import PaginationArrow from "./icons/pagination_arrow.svg";
import PaginationArrowBig from "./icons/pagination_arrow_big.svg";
import PaginationArrowBigDisabled from "./icons/pagination_arrow_big_disabled.svg";
import PaginationArrowDisabled from "./icons/pagination_arrow_disabled.svg";
import PasswordIsEmptyRed from "./icons/password_empty_icon_red.svg";
import Percent from "./icons/percent.svg";
import PersDetails from "./icons/pers_details.svg";
import Personal from "./icons/personal.svg";
import StockIconPfizer from "./icons/Pfizer_Inc.svg";
import Plans from "./icons/plans.svg";
import PlusButton from "./icons/plusButton.svg";
import Portfel from "./icons/portfel.svg";
import Portfolio from "./icons/portfolio.svg";
import PortfolioGray from "./icons/portfolio_gray.svg";
import PortfolioIcon from "./icons/portfolio_icon.svg";
import PreIpo from "./icons/pre_ipo.svg";
import MinilalUserIcon from "./icons/profile.svg";
import Question from "./icons/question.svg";
import Reits3 from "./icons/reits3.svg";
import Reject from "./icons/reject.svg";
import Reload from "./icons/reload.svg";
import Replenishment from "./icons/replenishment.svg";
import Report from "./icons/report.svg";
import Requisites from "./icons/requisites.svg";
import SelectArrow from "./icons/selectArrow.svg";
import Shield from "./icons/shield.svg";
import Star from "./icons/star.svg";
import StructureProduct24 from "./icons/structure-product_24.svg";
import Substract from "./icons/subtract.svg";
import SuccessRegister from "./icons/successRegister.svg";
import Suitcase from "./icons/suitcase.svg";
import SumProduct from "./icons/sum_product_icon.svg";
import Sunrun from "./icons/sunrun.svg";
import TestIcon from "./icons/test.svg";
import Time from "./icons/time.svg";
import TimeBlack from "./icons/time_black.svg";
import Toggles from "./icons/toggles.svg";
import TooltipIcon from "./icons/tooltip/tooltip-icon.svg";
import Trade from "./icons/trade.svg";
import Tranche from "./icons/Tranche.svg";
import Trash from "./icons/trash.svg";
import TrashIcon from "./icons/trashIcon.svg";
import TriangleOrange from "./icons/triangleOrange.svg";
import Umbrella from "./icons/umbrella.svg";
import UserProfile from "./icons/userProfile.svg";
import Vector from "./icons/vector.svg";
import W8 from "./icons/W8.svg";
import WalletGrey from "./icons/wallet_grey.svg";
import Warning from "./icons/warning.svg";
import Withdraw_2 from "./icons/withdraw_2.svg";
import Withdraw3 from "./icons/withdraw_3.svg";

const map = {
  "arrow-downward-rounded": ArrowDownwardRoundedSvg,
  accountInvestment: AccountInvestment,
  addCountry: AddCountry,
  building: Building,
  education: Education,
  changeLang: ChangeLangSvg,
  charts: Charts,
  candles: Candles,
  contactUs2: ContactUs2,
  ContactUs2White: ContactUs2_white,
  checkedCheckbox: CheckedCheckbox,
  checkbox: Checkbox,
  checkboxRed: CheckboxRed,
  checkboxBlack: CheckboxBlack,
  dot: Dot,
  filters: Filters,
  goForward: GoForwardSvg,
  button_plus: ButtonPlus,
  bag: Bag,
  dollar_coin: DollarCoin,
  star: Star,
  toggles: Toggles,
  double_triangle: DoubleTriangle,
  calculator: Calculator,
  bell: Bell,
  bellGrey: BellGrey,
  minimalUser: MinilalUserIcon,
  minimalGreyUser: MinimalGreyUserIcon,
  exclamation: Exclamation,
  default_product: DefaultProduct,
  warning: Warning,
  minusButton: MinusButton,
  plusButton: PlusButton,
  trashIcon: TrashIcon,
  deleteTrashIcon: DeleteTrashIcon,
  addIcon: AddIcon,
  burgerMinimal: BurgerMinimalButton,
  burgerMinimalWhite: BurgerMinimalWhiteButton,
  backButton: BackButton,
  coupon: Coupon,
  crossStandart: CrossStandart,
  informationGrey: InformationGrey,
  reject: Reject,
  exitBlack: ExitBlack,
  download: Download,
  Clear: Clear,
  info: Info,
  close: Close,
  close2: Close2,
  modalCross: ModalCross,
  time: Time,
  requisites: Requisites,
  withdraw_2: Withdraw_2,
  withdrawDarkGrey: WithdrawDarkGrey,
  depositDarkGrey: DepositDarkGrey,
  doubleArrow: DoubleArrow,
  circleIcon1: CircleIcon1,
  circleIcon2: CircleIcon2,
  circleIcon3: CircleIcon3,
  circleIcon4: CircleIcon4,
  circleIcon5: CircleIcon5,
  circleIcon6: CircleIcon6,
  houseGray: HouseGray,
  portfolioGray: PortfolioGray,
  blockAccount: BlockAccount,
  authError: AuthError,
  errorSquareIcon: ErrorSquareIcon,
  accountAlreadyRegistered: AccountAlreadyRegistered,
  SuccessRegister: SuccessRegister,
  triangleOrange: TriangleOrange,
  downloadCircle: DownloadCircle,
  alto: Alto,
  disabledDownload: DisabledDownload,
  downloadSuccess: DownloadSuccess,
  replenishment: Replenishment,
  modalCrossNew: ModalCrossNew,
  reload: Reload,
  withdraw3: Withdraw3,
  currrencyCny: CurrrencyCny,
  currrencyRub: CurrrencyRub,
  currrencyEur: CurrrencyEur,
  contractPlus: ContractPlus,
  currrencyUsd: CurrrencyUsd,
  i: I,
  docs: Docs,
  docsBlack: DocsBlack,
  accordionArrow: AccordionArrow,
  accordionArrowWhite: AccordionArrowWhite,
  magnifire2: Magnifire2,
  forward: Forward,
  preIpo: PreIpo,
  note: Note,
  notesPending: NotesPending,
  okta: Okta,
  sunrun: Sunrun,
  bonds: Bonds,
  like: Like,
  likeFilled: LikeFilled,
  likeFilter: LikeFilter,
  likeFilterFilled: LikeFilterFilled,
  lenta: Lenta,
  lockProfessional: LockProfessional,
  flag: Flag,
  fire: Fire,
  bankIconMinimalActive: BankIconMinimalActive,
  NotSecurityQuestionIcon: NotSecurityQuestionIcon,
  PasswordIsEmptyRed: PasswordIsEmptyRed,
  shield: Shield,
  stockIconCisco: StockIconCisco,
  stockIconCocaCola: StockIconCocaCola,
  stockIconConstellation: StockIconConstellation,
  stockIconCVSHealth: StockIconCVSHealth,
  stockIconIncyte: StockIconIncyte,
  stockIconMicrosoft: StockIconMicrosoft,
  stockIconPfizer: StockIconPfizer,
  structureProduct24: StructureProduct24,
  starEmpty: StarEmpty,
  trade: Trade,
  pie: Pie,
  goldStar: GoldStar,
  infoIcon: InfoIcon,
  selectArrow: SelectArrow,
  dateCalendar: DateCalendar,
  dateCalendarBlack: DateCalendaBlack,
  chevronAccordion: ChevronAccordion,
  checkboxDisable: CheckboxDisable,
  checkboxGreen: CheckboxGreen,
  checkedCheckboxDisable: CheckedCheckboxDisable,
  trash: Trash,
  chevronLeft: ChevronLeft,
  timeBlack: TimeBlack,
  blackCircle: BlackCircle,
  grayCircle: GrayCircle,
  forYouCircleBig: ForYouCircleBig,
  crownGold: CrownGold,
  crownWhite: CrownWhite,
  crownGrey: CrownGrey,
  userProfile: UserProfile,
  mainExit: MainExit,
  question: Question,
  lock: Lock,
  vector: Vector,
  flash: Flash,
  numbersRound1: NumbersRound1,
  numbersRound2: NumbersRound2,
  numbersRound3: NumbersRound3,
  numbersRound4: NumbersRound4,
  numbersRound5: NumbersRound5,
  numbersRound6: NumbersRound6,
  numbersRound7: NumbersRound7,
  numbersRound8: NumbersRound8,
  numbersRound9: NumbersRound9,
  minimalDollar: MinimalDollar,
  minimalRub: MinimalRub,
  minimalCny: MinimalCny,
  minimalDh: MinimalDh,
  minimalEur: MinimalEur,
  downloadDoc: DownloadDoc,
  note24: Note24,
  documentIcon: DocumentIcon,
  downloadIconBlack: DownloadIconBlack,
  fund5Icon: Fund5Icon,
  fund3Icon: Fund3Icon,
  globalBondsIcon: GlobalBondsIcon,
  globalEquitiens: GlobalEquitiens,
  substract: Substract,
  greyCross: GreyCross,
  walletGrey: WalletGrey,
  incomeFund3: IncomeFund3,
  incomeWallet: IncomeWallet,
  outcomeWallet: OutcomeWallet,
  reits3: Reits3,
  tooltipIcon: TooltipIcon,
  menuForYou: MenuForYou,
  menuOperations: MenuOperations,
  menuDocuments: MenuDocuments,
  menuServices: MenuServices,
  menuProfile: MenuProfile,
  paginationArrow: PaginationArrow,
  paginationArrowDisabled: PaginationArrowDisabled,
  paginationArrowBig: PaginationArrowBig,
  paginationArrowBigDisabled: PaginationArrowBigDisabled,
  percent: Percent,
  plans: Plans,
  umbrella: Umbrella,
  tranche: Tranche,
  report: Report,
  portfel: Portfel,
  noReports: NoReports,
  identifyIcon: IdentifyIcon,
  accountCardIcon: AccountCardIcon,
  accomplished: Accomplished,
  assetsEuro: AssetsEuro,
  assetsDirham: AssetsDirham,
  assetsCNY: AssetsCNY,
  assetsDollar: AssetsDollar,
  assetsRub: AssetsRub,
  portfolio: Portfolio,
  check_details: Check_details,
  knight: Knight,
  global: Global,
  portfolioIcon: PortfolioIcon,
  marginTrade: MarginTrade,
  flashII: FlashII,
  personal: Personal,
  sumProduct: SumProduct,
  personalDetails: PersDetails,
  suitcase: Suitcase,
  testIcon: TestIcon,
  W8: W8,
  bankSection: BankSection,
  documentsIcon: DocumentsIcon,
  confirmationDocs: ConfirmationDocs,
  identityDoc: IdentityDoc,
  messageIcon: MessageIcon,
};
export type IconNameType = keyof typeof map;

export type IconSize =
  | 720
  | 370
  | 150
  | 100
  | 81
  | 70
  | 68
  | 56
  | 50
  | 40
  | 32
  | 30
  | 27
  | 24
  | 20
  | 19
  | 18
  | 16
  | 14
  | 10
  | 8;

interface ImgIconProps extends IconProps {
  icon?: string;
}

export interface IconProps {
  className?: string;
  size?: IconSize;
  name?: IconNameType;
  icon?: string | FunctionComponent<SVGProps<SVGSVGElement>>;
  fallbackSrc?: string;
  children?: any;
}

const useStyles = makeStyles<IconProps>()((_, props) => ({
  root: {
    display: "inline-block",
    width: 20,
    height: 20,
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  size: {
    minWidth: props.size,
    width: props.size,
    height: props.size,
  },
  name:
    props.name && map[props.name]
      ? {
          backgroundImage: `url("${map[props.name]}")`,
          backgroundRepeat: "no-repeat",
        }
      : {},
}));

const ImgIcon = ({ className, name, size = 20, icon, fallbackSrc }: ImgIconProps): ReactElement => {
  const { classes } = useStyles({ name, size });
  const [imageSrc, setImageSrc] = useState<string | undefined>(icon);

  const handleImageError = () => {
    if (fallbackSrc) {
      setImageSrc(fallbackSrc);
    }
  };

  return <img src={imageSrc} className={cn(classes.size, className)} onError={handleImageError} />;
};

/**
 *
 * @param className
 * @param name - takes img name from map
 * @param size - default is 20px
 * @param icon - SVGComponent(can be controlled by classname) or image source(for backend img urls)
 * @param fallbackSrc - if there is no image source(from backend) change it on fallbackSrc
 * @returns i, img or svg element
 */

const Icon = ({ className, name, size = 20, icon, fallbackSrc }: IconProps): ReactElement => {
  const { classes } = useStyles({ name, size });

  if (icon) {
    if (typeof icon === "string") {
      return <ImgIcon className={className} name={name} size={size} icon={icon} fallbackSrc={fallbackSrc} />;
    }

    const SVGIcon = icon;
    return <SVGIcon className={cn(classes.size, className)} />;
  }

  return <i className={cn(classes.root, classes.size, classes.name, className)} />;
};

export default Icon;
