import React, { lazy, Suspense, useLayoutEffect, useReducer } from "react";
import { Router as MainRouter } from "react-router-dom";
import { useStore } from "effector-react";
import { Update } from "history";

import { NotAuthRoutes, unauthorizedRoutes } from "~/app/router/components/NotAuthRoutes";
import { TechnicalWorks } from "~/app/router/components/TechnicalWorks";
import history from "~/configs/history";
import { links, notForRedirectPath, routes } from "~/constants/enums";
import Loader from "~/features/Loader";
import { technicalWorksModel } from "~/modules/app/technical-works-model";
import { currentClientModel } from "~/modules/current-client-model";
import { loginModel } from "~/modules/keycloak/login-model";
const AuthRoutes = lazy(() => import("~/app/router/components/AuthRoutes"));

export const Router = () => {
  const { $isLogin } = loginModel;
  const { $isTechnicalWorks } = technicalWorksModel;
  const isLogin = useStore($isLogin);
  const isTechnicalWorks = useStore($isTechnicalWorks);

  const renderRoutes = () => {
    if (isTechnicalWorks) return <TechnicalWorks />;
    if (isLogin) {
      return (
        <Suspense fallback={<Loader />}>
          <AuthRoutes />
        </Suspense>
      );
    }
    return <NotAuthRoutes />;
  };

  return <HistoryRouter>{renderRoutes()}</HistoryRouter>;
};

const reducer = (_: Update, action: Update) => action;

type HistoryRouterProps = {
  children: React.ReactNode;
};

export const HistoryRouter = ({ children }: HistoryRouterProps) => {
  const { $currentClient } = currentClientModel;
  const currentClient = useStore($currentClient);

  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    if (
      !unauthorizedRoutes.find((el) => el.path === history.location.pathname) &&
      !currentClient &&
      history.location.pathname !== routes.notAvailable
    ) {
      if (notForRedirectPath.includes(history.location?.pathname as routes | links)) {
        sessionStorage.setItem("redirectPath", links.operationsAssets);
      } else {
        sessionStorage.setItem("redirectPath", history.location?.pathname);
      }
    }
  }, [history.location?.pathname]);

  useLayoutEffect(() => history.listen(dispatch), []);

  return (
    <MainRouter navigationType={state.action} location={state.location} navigator={history}>
      {children}
    </MainRouter>
  );
};
