import React, { forwardRef } from "react";
import MuiLoaderIcon from "@mui/material/CircularProgress";
import cn from "classnames";
import omit from "lodash/omit";

import useStyles from "./styles";

type TStyleKeys = keyof ReturnType<typeof useStyles>["classes"];

export enum TypeStyleBaseButtonEnum {
  black = "black",
  default = "default",
  white = "white",
  inline = "inline",
  grey = "grey",
}

export interface BaseButtonProps extends React.ComponentProps<"button"> {
  typeStyle?: TStyleKeys & keyof typeof TypeStyleBaseButtonEnum;
  isLoader?: boolean;
}

export type ButtonRef = HTMLButtonElement | null;

export default forwardRef((props: BaseButtonProps, ref: React.ForwardedRef<ButtonRef>) => {
  const propsButton = omit(props, ["typeStyle", "isLoader"]);
  const { classes } = useStyles();
  const { typeStyle = "default" } = props;

  return (
    <button
      {...propsButton}
      ref={ref}
      className={cn(classes.reset, classes.baseButton, classes[typeStyle], props.className)}
    >
      {props.isLoader && <MuiLoaderIcon size={16} />}
      {props.children}
    </button>
  );
});
