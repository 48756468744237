// eslint-disable-next-line simple-import-sort/imports
import React, { useCallback, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { AppBar, Box, Container, Grid, Tab, Tabs, TabsActions, Toolbar, Tooltip } from "@mui/material";
import { useStore } from "effector-react";
import cn from "classnames";

import { useTranslation } from "~/i18n";
import AppIconViewport from "~/features/AppIconViewport";
import UserDropdownButton from "./components/UserDropdownButton";
import SwitchLocaleViewport from "~/features/SwitchLocaleViewport";
import ContentWrapperViewport from "~/features/ContentWrapperViewport";
import { ContactUs } from "~/features/ContactUs";
import Icon from "~/shared/Icon";
import { ReactComponent as CloseIcon } from "~/shared/Icon/icons/menu/close.svg";
import { colors, colorsLogo, filtres, links } from "~/constants/enums";
import { headerModel } from "~/modules/header-model";
import { useHeaderStyles } from "~/app/layouts/BaseLayout/components/ViewportHeader/styles";
import { edmService } from "~/pages/EDM/services/edm-services";
import { urlBack, useRouterHooks } from "~/lib/router";
import { logoutModel } from "~/modules/keycloak/logout-model";

interface HeaderProps {
  unAuthorized?: boolean;
  isMenu?: boolean;
}

// This callback updates tabsIndicator position after mount
const tabsActionCallback = (ref: TabsActions | null): void => {
  if (ref && ref.updateIndicator) {
    setTimeout(ref.updateIndicator, 100);
  }
};

const matchPaths = (pathname: string): { pathname: string } | null => {
  return (
    [links.operations, links.forYou]
      .map((item): { pathname: string } | null =>
        matchPath(
          {
            path: item,
          },
          pathname
        )
      )
      .find(Boolean) || null
  );
};

const ViewportHeader = ({ unAuthorized, isMenu }: HeaderProps) => {
  const { $isEdm } = edmService;

  const isEdm = useStore($isEdm);
  const { navigate, location } = useRouterHooks();

  const matches = matchPaths(location.pathname);

  const [isSideMenu, setIsSideMenu] = useState(false);

  const { t } = useTranslation();
  const { classes } = useHeaderStyles();

  const handleTabChange = useCallback(
    (_: unknown, value: string): void => {
      if (!matches || matches.pathname !== value || value !== location.pathname) {
        navigate(value);
      }
    },
    [navigate, matches, location.pathname]
  );

  const selected =
    location.pathname.includes(links.operations) || location.pathname.includes(links.openAssetManagement);
  const selectedForYou = location.pathname.includes(links.forYou);
  const selectedServices = location.pathname.includes(links.services);

  const handleOperations = useCallback((): void => {
    navigate(links.operationsAssets);
    setIsSideMenu(false);
  }, [navigate]);

  const handleForYou = useCallback((): void => {
    navigate(links.forYouCommon);
    setIsSideMenu(false);
  }, [navigate]);

  const handleShowDocs = useCallback((): void => {
    navigate(links.servicesDocuments, { state: { filterDefault: filtres.docs } });
    setIsSideMenu(false);
  }, [navigate]);

  const handleShowProfile = useCallback((): void => {
    navigate(links.profile);
    setIsSideMenu(false);
  }, [navigate]);

  const classesSelectedTab = useCallback((isSelected: boolean) => {
    return cn({ [classes.selected]: isSelected, [classes.tab]: !isSelected });
  }, []);

  useEffect(() => {
    document.body.style.width = "100%";

    return () => {
      document.body.style.width = "";
    };
  }, []);

  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  return (
    <AppBar
      position={headerTheme.isStaticMobileHeader ? "static" : "sticky"}
      className={classes.appBar}
      style={headerTheme.isDarkColorTheme ? { background: headerTheme.background } : {}}
    >
      {!unAuthorized && (
        <Box className={cn(classes.sideMenu, { [classes.isSideMenu]: isSideMenu })}>
          <Box className={classes.menuItem} onClick={handleShowProfile}>
            {t("MENU.USER.profile")}
          </Box>
          {!isEdm && (
            <>
              <Box className={classes.menuItem} onClick={handleOperations}>
                {t("MENU.HEADER.operations")}
              </Box>
              <Box className={classes.menuItem} onClick={handleForYou}>
                {t("MENU.HEADER.forYou")}
              </Box>
              <Box className={classes.menuItem} onClick={handleShowDocs}>
                {t("DOCUMENTS.label")}
              </Box>
            </>
          )}
          <Box className={classes.menuItem} onClick={() => logoutModel.logout()}>
            {t("MENU.USER.exit")}
          </Box>
        </Box>
      )}
      <Container className={classes.h100}>
        <ContentWrapperViewport className={classes.headerContentWrapper}>
          <Toolbar className={`${classes.h100} ${classes.headerContainer}`} disableGutters>
            <Grid item>
              {!unAuthorized ? (
                <AppIconViewport
                  color={headerTheme.isDarkColorTheme ? colorsLogo.white : colorsLogo.dark}
                  wrapWithRootLink
                />
              ) : (
                <a href="https://www.bcscapitaldifc.com/">
                  <AppIconViewport color={colorsLogo.dark} />
                </a>
              )}
            </Grid>
            {!unAuthorized && !headerTheme.isHiddenTabMenu && (
              <Grid item className={classes.tabs}>
                <Tabs
                  value={matches?.pathname || false}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor={headerTheme.isDarkColorTheme ? "primary" : "secondary"}
                  className={cn(classes.h100, classes.tabsContainer)}
                  action={tabsActionCallback}
                >
                  <Tab
                    className={classesSelectedTab(selected)}
                    label={t("MENU.HEADER.operations")}
                    value={links.operationsAssets}
                    disableRipple
                  />
                  <Tab
                    label={t("MENU.HEADER.forYou")}
                    value={links.forYouCommon}
                    className={classesSelectedTab(selectedForYou)}
                    disableRipple
                  />
                  <Tab
                    label={t("MENU.HEADER.services")}
                    value={links.servicesAll}
                    className={`${classesSelectedTab(selectedServices)} selected`}
                    disableRipple
                  />
                </Tabs>
              </Grid>
            )}
            <div className={classes.userTabsBox}>
              {!unAuthorized && (
                <Grid item className={classes.userDropdownWrapper}>
                  <UserDropdownButton />
                </Grid>
              )}
              <Grid item className={classes.space}>
                <SwitchLocaleViewport colorText={colors.black} withoutIcon sizeIcon={20} className="onlyText" />
              </Grid>
              {!unAuthorized && (
                <Tooltip title={t("MENU.HEADER.tooltip")} className={classes.sendMessage} enterTouchDelay={0}>
                  <Grid item className={classes.space}>
                    <ContactUs to={links.sendMessage} />
                  </Grid>
                </Tooltip>
              )}
              {isSideMenu && !unAuthorized && (
                <div className={classes.closeMenuElem} onClick={() => isSideMenu && setIsSideMenu(!isSideMenu)}></div>
              )}
              {!unAuthorized &&
                (isMenu ? (
                  <Box
                    onClick={() => {
                      const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfoMenu");
                      if (portfolioNoteInfo) {
                        localStorage.setItem("portfolioNoteInfo", portfolioNoteInfo);
                        localStorage.removeItem("portfolioNoteInfoMenu");
                      }
                      navigate(urlBack);
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </Box>
                ) : (
                  <Box
                    className={classes.burgerButton}
                    onClick={() => {
                      const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfo");
                      if (portfolioNoteInfo) {
                        localStorage.setItem("portfolioNoteInfoMenu", portfolioNoteInfo);
                      }
                      navigate(links.mobileMenu);
                    }}
                  >
                    <Icon
                      className={classes.burgerButtonIcon}
                      name={headerTheme.isDarkColorTheme ? "burgerMinimalWhite" : "burgerMinimal"}
                    />
                  </Box>
                ))}
            </div>
          </Toolbar>
        </ContentWrapperViewport>
      </Container>
    </AppBar>
  );
};

export default ViewportHeader;
